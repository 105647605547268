import { createSlice } from '@reduxjs/toolkit';
import { generalConfig } from '../../config';
import { uniqBy, filter } from 'lodash';

const initialState = {
  isLoading: false,
  error: false,
  bitacora: null,
  selectedBitacora: null,
  bitacoraList: [],
  bitacoraByVehicleList: [],
  checkout: {
    noveltyList: []
  },
  updateCheckout: {
    noveltyList: []
  }
};

const slice = createSlice({
  name: 'bitacora',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setBitacora(state, action) {
      state.isLoading = false;
      state.bitacoraList = [...state.bitacoraList, action.payload];
    },
    setBitacoraList(state, action) {
      state.isLoading = false;
      state.bitacoraList = action.payload;
    },
    setSelectedBitacora(state, action) {
      state.isLoading = false;
      const id = action.payload;
      const index = state.bitacoraList.findIndex((x) => x._id === id);
      const selected = state.bitacoraList[index];
      state.selectedBitacora = selected;
    },
    updateBitacora(state, action) {
      const updatedBitacora = action.payload;

      const copyList = state.bitacoraList;

      const index = copyList.findIndex(
        (x) => x.id_interno === updatedBitacora.id_interno
      );

      copyList[index] = updatedBitacora;

      state.bitacoraList = copyList;
    },
    removeBitacora(state, action) {
      const id_interno = action.payload;

      const copyList = state.bitacoraList;

      const index = copyList.findIndex((x) => x.id_interno === id_interno);

      copyList.splice(index, 1);

      state.bitacoraList = copyList;
    },
    setBitacoraByVehicleList(state, action) {
      state.isLoading = false;

      const updatedList = filter(action.payload, (item) => {
        return item.contador !== 0;
      });

      state.bitacoraByVehicleList = updatedList;
    },
    //Checkout for add noveltyList
    addNoveltyCheckout(state, action) {
      state.isLoading = false;
      const novelty = action.payload;
      state.checkout.noveltyList = uniqBy(
        [...state.checkout.noveltyList, novelty],
        'detalle'
      );
    },
    removeNoveltyCheckout(state, action) {
      state.isLoading = false;
      const updatedList = filter(state.checkout.noveltyList, (item) => {
        return item.detalle !== action.payload;
      });

      state.checkout.noveltyList = updatedList;
    },
    resetCheckoutList(state, action) {
      state.isLoading = false;
      state.checkout.noveltyList = [];
    },
    //Checkout for update noveltyList
    setNoveltyListUpdateCheckout(state, action) {
      state.isLoading = false;
      state.updateCheckout.noveltyList = action.payload;
    },
    addNoveltyUpdateCheckout(state, action) {
      state.isLoading = false;
      const novelty = action.payload;
      state.updateCheckout.noveltyList = uniqBy(
        [...state.updateCheckout.noveltyList, novelty],
        'detalle'
      );
    },
    removeNoveltyUpdateCheckout(state, action) {
      state.isLoading = false;
      const id_interno = action.payload;
      const updatedList = filter(state.updateCheckout.noveltyList, (item) => {
        return item.id_interno !== id_interno;
      });

      state.updateCheckout.noveltyList = updatedList;
    },
    resetUpdateCheckoutList(state, action) {
      state.isLoading = false;
      state.updateCheckout.noveltyList = [];
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setSelectedBitacora,
  addNoveltyCheckout,
  removeNoveltyCheckout,
  resetCheckoutList
} = slice.actions;

// ----------------------------------------------------------------------

export function getBitacoraList() {
  const accessToken = window.localStorage.getItem('accessToken');
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const query = await fetch(`${generalConfig.baseUrl}/api/v1/bitacoras`, {
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      });
      const response = await query.json();

      dispatch(slice.actions.setBitacoraList(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export const addBitacora = (values) => async (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken');
  dispatch(slice.actions.startLoading());
  try {
    const query = await fetch(
      `${generalConfig.baseUrl}/api/v1/agregar_bitacora`,
      {
        method: 'POST',
        body: JSON.stringify(values),
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      }
    );
    const response = await query.json();

    if (response.message === 'error') {
      return response;
    }

    dispatch(slice.actions.setBitacora(response.body));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};

// ----------------------------------------------------------------------

export const updateGeneralBitacora = (values) => async (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken');
  dispatch(slice.actions.startLoading());
  try {
    const query = await fetch(
      `${generalConfig.baseUrl}/api/v1/editar_bitacora`,
      {
        method: 'PUT',
        body: JSON.stringify(values),
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      }
    );
    const response = await query.json();
    console.log('response', response);

    if (response.message === 'error') {
      return response;
    }
    const updatedBitacora = {
      ...response.body
    };
    dispatch(slice.actions.updateBitacora(updatedBitacora));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};

// ----------------------------------------------------------------------

export const removeBitacora = (id_interno) => async (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken');
  dispatch(slice.actions.startLoading());
  try {
    const query = await fetch(
      `${generalConfig.baseUrl}/api/v1/eliminar_bitacora/${id_interno}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      }
    );
    const response = await query.json();
    if (response.message === 'error') {
      return response;
    }
    dispatch(slice.actions.removeBitacora(id_interno));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};

// ----------------------------------------------------------------------

export function getBitacoraByVehicleList(id_vehiculo) {
  const accessToken = window.localStorage.getItem('accessToken');
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const query = await fetch(
        `${generalConfig.baseUrl}/api/v1/bitacoras_por_vehiculo/${id_vehiculo}`,
        {
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${accessToken}`
          }
        }
      );
      const response = await query.json();

      dispatch(slice.actions.setBitacoraByVehicleList(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

//Lista de novedades para actualizar
// ----------------------------------------------------------------------

export function getNoveltyByBitacora(id_interno) {
  const accessToken = window.localStorage.getItem('accessToken');
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const query = await fetch(
        `${generalConfig.baseUrl}/api/v1/novedades_por_bitacora/${id_interno}`,
        {
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${accessToken}`
          }
        }
      );
      const response = await query.json();
      console.log('esto trae', response);

      dispatch(slice.actions.setNoveltyListUpdateCheckout(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export const addNoveltyToBitacora = (values) => async (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken');
  dispatch(slice.actions.startLoading());
  try {
    const query = await fetch(
      `${generalConfig.baseUrl}/api/v1/agregar_novedad_bitacora`,
      {
        method: 'POST',
        body: JSON.stringify(values),
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      }
    );
    const response = await query.json();

    if (response.message === 'error') {
      return response;
    }

    dispatch(slice.actions.addNoveltyUpdateCheckout(response.body));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};

// ----------------------------------------------------------------------

export const removeNoveltyFromBitacora = (id_interno) => async (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken');
  dispatch(slice.actions.startLoading());
  try {
    const query = await fetch(
      `${generalConfig.baseUrl}/api/v1/eliminar_novedad_from_bitacora/${id_interno}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      }
    );
    const response = await query.json();
    if (response.message === 'error') {
      return response;
    }
    dispatch(slice.actions.removeNoveltyUpdateCheckout(id_interno));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};
