import { createSlice } from '@reduxjs/toolkit';
import { generalConfig } from '../../config';

const initialState = {
  isLoading: false,
  error: false,
  guide: null,
  selectedGuide: null,
  guideList: [],
  pdfByGuide: {
    pdf: ''
  },
  scheduleListByGuide: []
};

const slice = createSlice({
  name: 'guide',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    endLoading(state) {
      state.isLoading = false;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setGuideList(state, action) {
      state.isLoading = false;
      state.guideList = action.payload;
    },
    addGuideSuccess(state, action) {
      state.isLoading = false;
      state.guideList = [...state.guideList, action.payload];
    },
    setSelectedGuide(state, action) {
      state.isLoading = false;
      const id = action.payload;
      const index = state.guideList.findIndex((x) => x._id === id);
      const selected = state.guideList[index];
      state.selectedGuide = selected;
    },
    updateGuide(state, action) {
      const updatedGuide = action.payload;

      const copyList = state.guideList;

      const index = copyList.findIndex(
        (x) => x.id_interno === updatedGuide.id_interno
      );

      copyList[index] = updatedGuide;

      state.guideList = copyList;
    },
    removeGuide(state, action) {
      const id_interno = action.payload;

      const copyList = state.guideList;

      const index = copyList.findIndex((x) => x.id_interno === id_interno);

      copyList.splice(index, 1);

      state.guideList = copyList;
    },
    setPdfByGuide(state, action) {
      state.isLoading = false;
      const pdf = action.payload;
      state.pdfByGuide.pdf = pdf;
    },
    setScheduleListByGuide(state, action) {
      state.isLoading = false;
      state.scheduleListByGuide = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { setSelectedGuide } = slice.actions;

// ----------------------------------------------------------------------

export function getGuideList() {
  const accessToken = window.localStorage.getItem('accessToken');
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const query = await fetch(`${generalConfig.baseUrl}/api/v1/pautas`, {
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      });
      const response = await query.json();

      dispatch(slice.actions.setGuideList(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export const addGuide = (formdata) => async (dispatch) => {
  // console.log('recibí un formdata', formdata.get('file'));
  const accessToken = window.localStorage.getItem('accessToken');
  dispatch(slice.actions.startLoading());
  try {
    const query = await fetch(`${generalConfig.baseUrl}/api/v1/agregar_pauta`, {
      method: 'POST',
      body: formdata,
      headers: {
        // 'Content-Type': 'application/json'
        // 'Content-Type': 'multipart/form-data'
        authorization: `Bearer ${accessToken}`
      }
    });
    const response = await query.json();

    if (response.message === 'error') {
      dispatch(slice.actions.endLoading());
      return response;
    }
    dispatch(slice.actions.addGuideSuccess(response.body));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};

// ----------------------------------------------------------------------

export const updateGuide = (formdata) => async (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken');
  dispatch(slice.actions.startLoading());
  try {
    const query = await fetch(`${generalConfig.baseUrl}/api/v1/editar_pauta`, {
      method: 'PUT',
      body: formdata,
      headers: {
        // 'Content-Type': 'multipart/form-data',
        authorization: `Bearer ${accessToken}`
      }
    });
    const response = await query.json();

    if (response.message === 'error') {
      dispatch(slice.actions.endLoading());
      return response;
    }

    dispatch(slice.actions.updateGuide(response.body));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};

// ----------------------------------------------------------------------

export const removeGuide = (id_interno) => async (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken');
  dispatch(slice.actions.startLoading());
  try {
    const query = await fetch(
      `${generalConfig.baseUrl}/api/v1/eliminar_pauta/${id_interno}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      }
    );
    const response = await query.json();
    if (response.message === 'error') {
      return response;
    }
    dispatch(slice.actions.removeGuide(id_interno));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};

// ----------------------------------------------------------------------

export function getPautaByPDF(id_interno) {
  const accessToken = window.localStorage.getItem('accessToken');
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const query = await fetch(
        `${generalConfig.baseUrl}/api/v1/pauta_pdf/${id_interno}`,
        {
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${accessToken}`
          }
        }
      );
      const response = await query.json();
      console.log(response);

      dispatch(slice.actions.setPdfByGuide(response.pdf));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------SCHEDULE BY GUIDE

export function getScheduleListByGuide(id_pauta) {
  const accessToken = window.localStorage.getItem('accessToken');
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const query = await fetch(
        `${generalConfig.baseUrl}/api/v1/cronogramas_por_pauta/${id_pauta}`,
        {
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${accessToken}`
          }
        }
      );
      const response = await query.json();
      dispatch(slice.actions.setScheduleListByGuide(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
