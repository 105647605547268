import { createSlice } from '@reduxjs/toolkit';
import { generalConfig } from '../../config';

const initialState = {
  isLoading: false,
  error: false,
  material: null,
  selectedMaterial: null,
  materialList: [],
  materialByTaskList: [],
  materialVisibleList: []
};

const slice = createSlice({
  name: 'material',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    endLoading(state) {
      state.isLoading = false;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    addMaterialSuccess(state, action) {
      state.isLoading = false;
      state.materialList = [...state.materialList, action.payload];
    },
    setMaterialList(state, action) {
      state.isLoading = false;
      state.materialList = action.payload;
    },
    setSelectedMaterial(state, action) {
      state.isLoading = false;
      const id = action.payload;
      const index = state.materialList.findIndex((x) => x._id === id);
      const selected = state.materialList[index];
      state.selectedMaterial = selected;
    },
    updateMaterialSuccess(state, action) {
      const updatedMaterial = action.payload;

      const copyList = state.materialList;

      const index = copyList.findIndex(
        (x) => x.id_interno === updatedMaterial.id_interno
      );

      copyList[index] = updatedMaterial;

      state.materialList = copyList;
    },
    removeMaterial(state, action) {
      const id_interno = action.payload;

      const copyList = state.materialList;

      const index = copyList.findIndex((x) => x.id_interno === id_interno);

      copyList.splice(index, 1);

      state.materialList = copyList;
    },

    setMaterialByTaskList(state, action) {
      state.isLoading = false;
      state.materialByTaskList = action.payload;
    },
    setMaterialTask(state, action) {
      state.isLoading = false;
      state.materialByTaskList = [...state.materialByTaskList, action.payload];
    },
    removeMaterialTask(state, action) {
      state.isLoading = false;
      const id_interno = action.payload;

      const copyList = state.materialByTaskList;

      const index = copyList.findIndex((x) => x.id_interno === id_interno);

      copyList.splice(index, 1);

      state.materialByTaskList = copyList;
    },

    setMaterialVisibleList(state, action) {
      state.isLoading = false;
      state.materialVisibleList = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { setSelectedMaterial } = slice.actions;

// ----------------------------------------------------------------------

export function getMaterialList() {
  const accessToken = window.localStorage.getItem('accessToken');
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const query = await fetch(`${generalConfig.baseUrl}/api/v1/articulos`, {
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      });
      const response = await query.json();

      dispatch(slice.actions.setMaterialList(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export const addMaterial = (values) => async (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken');

  dispatch(slice.actions.startLoading());
  try {
    const query = await fetch(
      `${generalConfig.baseUrl}/api/v1/agregar_articulo`,
      {
        method: 'POST',
        body: JSON.stringify(values),
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      }
    );
    const response = await query.json();

    if (response.message === 'error') {
      dispatch(slice.actions.endLoading());
      return response;
    }
    const newMaterial = {
      ...values,
      id_interno: response.body
    };
    dispatch(slice.actions.addMaterialSuccess(newMaterial));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};

// ----------------------------------------------------------------------

export const updateMaterial = (values) => async (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken');
  dispatch(slice.actions.startLoading());
  try {
    const query = await fetch(
      `${generalConfig.baseUrl}/api/v1/editar_articulo`,
      {
        method: 'PUT',
        body: JSON.stringify(values),
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      }
    );
    const response = await query.json();
    if (response.message === 'error') {
      dispatch(slice.actions.endLoading());
      return response;
    }
    const updatedMaterial = {
      ...values
    };
    dispatch(slice.actions.updateMaterialSuccess(updatedMaterial));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};

// ----------------------------------------------------------------------

export const removeMaterial = (id_interno) => async (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken');
  dispatch(slice.actions.startLoading());
  try {
    const query = await fetch(
      `${generalConfig.baseUrl}/api/v1/eliminar_articulo/${id_interno}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      }
    );
    const response = await query.json();
    if (response.message === 'error') {
      dispatch(slice.actions.endLoading());
      return response;
    }
    dispatch(slice.actions.removeMaterial(id_interno));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};

// ----------------------------------------------------------------------

export function getMaterialByGuideList(id_interno) {
  const accessToken = window.localStorage.getItem('accessToken');
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const query = await fetch(
        `${generalConfig.baseUrl}/api/v1/tareas_por_pauta/${id_interno}`,
        {
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${accessToken}`
          }
        }
      );
      const response = await query.json();

      dispatch(slice.actions.setMaterialByGuideList(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getMaterialByTaskList(id_interno) {
  const accessToken = window.localStorage.getItem('accessToken');
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const query = await fetch(
        `${generalConfig.baseUrl}/api/v1/articulos_por_tarea/${id_interno}`,
        {
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${accessToken}`
          }
        }
      );
      const response = await query.json();
      console.log('articulos por tarea', response);

      dispatch(slice.actions.setMaterialByTaskList(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getMaterialVisibleList() {
  const accessToken = window.localStorage.getItem('accessToken');
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const query = await fetch(
        `${generalConfig.baseUrl}/api/v1/articulos_para_tarea/`,
        {
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${accessToken}`
          }
        }
      );
      const response = await query.json();
      console.log('response articulos para tarea', response);

      dispatch(slice.actions.setMaterialVisibleList(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function addMaterialTask(values) {
  console.log('values antes de', values);
  const accessToken = window.localStorage.getItem('accessToken');
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const query = await fetch(
        `${generalConfig.baseUrl}/api/v1/agregar_articulo_tarea/`,
        {
          method: 'POST',
          body: JSON.stringify(values),
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${accessToken}`
          }
        }
      );
      const response = await query.json();

      console.log('response', response);

      if (response.message === 'error') {
        return response;
      }

      const newMaterial = {
        id_interno: response.body,
        id_articulo: values.id_material,
        id_tarea: values.id_tarea,
        descripcion: values.material.descripcion,
        cantidad: values.cantidad,
        tipo: values.material.tipo
      };

      dispatch(slice.actions.setMaterialTask(newMaterial));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export const removeMaterialTask = (id_interno, id_tarea) => async (
  dispatch
) => {
  const accessToken = window.localStorage.getItem('accessToken');
  dispatch(slice.actions.startLoading());
  try {
    const query = await fetch(
      `${generalConfig.baseUrl}/api/v1/eliminar_articulo_tarea/${id_interno}/${id_tarea}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      }
    );
    const response = await query.json();
    if (response.message === 'error') {
      return response;
    }
    dispatch(slice.actions.removeMaterialTask(id_interno));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};
