// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS = {
  auth: '/auth',
  app: '/',
  operation: '/operation'
};

export const PATH_PAGE = {
  auth: {
    root: ROOTS.auth,
    login: path(ROOTS.auth, '/login'),
    loginUnprotected: path(ROOTS.auth, '/login-unprotected'),
    register: path(ROOTS.auth, '/register'),
    registerUnprotected: path(ROOTS.auth, '/register-unprotected'),
    resetPassword: path(ROOTS.auth, '/reset-password'),
    verify: path(ROOTS.auth, '/verify')
  },
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment'
};

export const PATH_APP = {
  root: ROOTS.app,
  workers: path(ROOTS.app, 'pizarra-personal'),
  indicators: path(ROOTS.app, 'indicadores'),
  profile: path(ROOTS.app, 'perfil'),
  vehicle: {
    root: path(ROOTS.app, 'vehiculos'),
    vehicles: path(ROOTS.app, 'vehiculos'),
    models: path(ROOTS.app, 'vehiculos/modelos')
  },
  maintenance: {
    root: path(ROOTS.app, 'mantenimiento'),
    maintenances: path(ROOTS.app, 'mantenimiento'),
    guides: path(ROOTS.app, 'mantenimiento/pautas'),
    tasks: path(ROOTS.app, 'mantenimiento/tareas'),
    materials: path(ROOTS.app, 'mantenimiento/articulos')
  }
};

export const PATH_OPERATION = {
  root: ROOTS.operation,
  binnacles: path(ROOTS.operation, 'bitácoras')
};
