import { createSlice } from '@reduxjs/toolkit';
import { generalConfig } from '../../config';

const initialState = {
  isLoading: false,
  error: false,
  motive: null,
  selectedMotive: null,
  motiveList: []
};

const slice = createSlice({
  name: 'motive',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setMotive(state, action) {
      state.isLoading = false;
      state.motiveList = [...state.motiveList, action.payload];
    },
    setMotiveList(state, action) {
      state.isLoading = false;
      state.motiveList = action.payload;
    },
    setSelectedMotive(state, action) {
      state.isLoading = false;
      const id = action.payload;
      const index = state.motiveList.findIndex((x) => x._id === id);
      const selected = state.motiveList[index];
      state.selectedMotive = selected;
    },
    updateMotive(state, action) {
      const updatedMotive = action.payload;

      const copyList = state.motiveList;

      const index = copyList.findIndex(
        (x) => x.id_interno === updatedMotive.id_interno
      );

      copyList[index] = updatedMotive;

      state.motiveList = copyList;
    },
    removeMotive(state, action) {
      const id_interno = action.payload;

      const copyList = state.motiveList;

      const index = copyList.findIndex((x) => x.id_interno === id_interno);

      copyList.splice(index, 1);

      state.motiveList = copyList;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { setSelectedMotive } = slice.actions;

// ----------------------------------------------------------------------

export function getMotiveList() {
  const accessToken = window.localStorage.getItem('accessToken');
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const query = await fetch(`${generalConfig.baseUrl}/api/v1/motivos`, {
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      });
      const response = await query.json();

      dispatch(slice.actions.setMotiveList(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export const addMotive = (values) => async (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken');
  dispatch(slice.actions.startLoading());
  try {
    const query = await fetch(`${generalConfig.baseUrl}/api/v1/agregar_tarea`, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${accessToken}`
      }
    });
    const response = await query.json();
    if (response.message === 'error') {
      return response;
    }
    const newMotive = {
      ...values,
      id_interno: response.body
    };
    dispatch(slice.actions.setMotive(newMotive));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};

// ----------------------------------------------------------------------

export const updateMotive = (values) => async (dispatch) => {
  // console.log('values en enedpoint', values);
  const accessToken = window.localStorage.getItem('accessToken');
  dispatch(slice.actions.startLoading());
  try {
    const query = await fetch(`${generalConfig.baseUrl}/api/v1/editar_tarea`, {
      method: 'PUT',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${accessToken}`
      }
    });
    const response = await query.json();
    if (response.message === 'error') {
      return response;
    }
    const updatedMotive = {
      ...values
    };
    dispatch(slice.actions.updateMotive(updatedMotive));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};

// ----------------------------------------------------------------------

export const removeMotive = (id_interno) => async (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken');
  dispatch(slice.actions.startLoading());
  try {
    const query = await fetch(
      `${generalConfig.baseUrl}/api/v1/eliminar_pauta/${id_interno}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      }
    );
    const response = await query.json();
    if (response.message === 'error') {
      return response;
    }
    dispatch(slice.actions.removeMotive(id_interno));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};

// ----------------------------------------------------------------------

export function getMotiveByGuideList(id_interno) {
  const accessToken = window.localStorage.getItem('accessToken');
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const query = await fetch(
        `${generalConfig.baseUrl}/api/v1/tareas_por_pauta/${id_interno}`,
        {
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${accessToken}`
          }
        }
      );
      const response = await query.json();

      dispatch(slice.actions.setMotiveByGuideList(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
