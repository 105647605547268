import { createSlice } from '@reduxjs/toolkit';
import { generalConfig } from '../../config';

const initialState = {
  isLoading: false,
  error: false,
  category: null,
  selectedCategory: null,
  categoryList: []
};

const slice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setCategory(state, action) {
      state.isLoading = false;
      state.categoryList = [...state.categoryList, action.payload];
    },
    setCategoryList(state, action) {
      state.isLoading = false;
      state.categoryList = action.payload;
    },
    setSelectedCategory(state, action) {
      state.isLoading = false;
      const id = action.payload;
      const index = state.categoryList.findIndex((x) => x._id === id);
      const selected = state.categoryList[index];
      state.selectedCategory = selected;
    },
    updateCategory(state, action) {
      const updatedCategory = action.payload;

      const copyList = state.categoryList;

      const index = copyList.findIndex(
        (x) => x.id_interno === updatedCategory.id_interno
      );

      copyList[index] = updatedCategory;

      state.categoryList = copyList;
    },
    removeCategory(state, action) {
      const id_interno = action.payload;

      const copyList = state.categoryList;

      const index = copyList.findIndex((x) => x.id_interno === id_interno);

      copyList.splice(index, 1);

      state.categoryList = copyList;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { setSelectedCategory } = slice.actions;

// ----------------------------------------------------------------------

export function getCategoryList() {
  const accessToken = window.localStorage.getItem('accessToken');
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const query = await fetch(`${generalConfig.baseUrl}/api/v1/categorias`, {
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      });
      const response = await query.json();

      dispatch(slice.actions.setCategoryList(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export const addCategory = (values) => async (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken');
  dispatch(slice.actions.startLoading());
  try {
    const query = await fetch(`${generalConfig.baseUrl}/api/v1/agregar_tarea`, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${accessToken}`
      }
    });
    const response = await query.json();
    if (response.message === 'error') {
      return response;
    }
    const newCategory = {
      ...values,
      id_interno: response.body
    };
    dispatch(slice.actions.setCategory(newCategory));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};

// ----------------------------------------------------------------------

export const updateCategory = (values) => async (dispatch) => {
  console.log('values en enedpoint', values);
  const accessToken = window.localStorage.getItem('accessToken');
  dispatch(slice.actions.startLoading());
  try {
    const query = await fetch(`${generalConfig.baseUrl}/api/v1/editar_tarea`, {
      method: 'PUT',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${accessToken}`
      }
    });
    const response = await query.json();
    if (response.message === 'error') {
      return response;
    }
    const updatedCategory = {
      ...values
    };
    dispatch(slice.actions.updateCategory(updatedCategory));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};

// ----------------------------------------------------------------------

export const removeCategory = (id_interno) => async (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken');
  dispatch(slice.actions.startLoading());
  try {
    const query = await fetch(
      `${generalConfig.baseUrl}/api/v1/eliminar_pauta/${id_interno}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      }
    );
    const response = await query.json();
    if (response.message === 'error') {
      return response;
    }
    dispatch(slice.actions.removeCategory(id_interno));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};

// ----------------------------------------------------------------------

export function getCategoryByGuideList(id_interno) {
  const accessToken = window.localStorage.getItem('accessToken');
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const query = await fetch(
        `${generalConfig.baseUrl}/api/v1/tareas_por_pauta/${id_interno}`,
        {
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${accessToken}`
          }
        }
      );
      const response = await query.json();

      dispatch(slice.actions.setCategoryByGuideList(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
