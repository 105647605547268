import { createSlice } from '@reduxjs/toolkit';
import { generalConfig } from '../../config';

const initialState = {
  isLoading: false,
  error: false,
  vehicleList: []
};

const slice = createSlice({
  name: 'vehicle',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    endLoading(state) {
      state.isLoading = false;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setVehicleList(state, action) {
      state.isLoading = false;

      //Cambiamos el nombre de empresa a todos los que no sea TRANSMIN
      const vehicleList = action.payload.map((item) => {
        return {
          ...item,
          empresa: item.empresa.includes('TRANSMIN')
            ? 'TRANSMIN'
            : 'PULLMANSANTAROSA'
        };
      });

      state.vehicleList = vehicleList;
    },
    addVehicleSuccess(state, action) {
      state.isLoading = false;
      state.vehicleList = [...state.vehicleList, action.payload];
    },
    setSelectedVehicle(state, action) {
      state.isLoading = false;
      const id = action.payload;
      const index = state.vehicleList.findIndex((x) => x._id === id);
      const selected = state.vehicleList[index];
      state.selectedVehicle = selected;
    },
    updateVehicleSuccess(state, action) {
      const vehicle = action.payload;

      const updatedVehiculo = {
        ...vehicle,
        empresa: vehicle.empresa.includes('TRANSMIN')
          ? 'TRANSMIN'
          : 'PULLMANSANTAROSA'
      };

      const copyList = state.vehicleList;

      const index = copyList.findIndex(
        (x) => x.id_interno === vehicle.id_interno
      );

      copyList[index] = updatedVehiculo;

      state.vehicleList = copyList;
    },
    removeVehicleSuccess(state, action) {
      const id = action.payload;

      const copyList = state.vehicleList;

      const index = copyList.findIndex((x) => x._id === id);

      copyList.splice(index, 1);

      state.vehicleList = copyList;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { setSelectedVehicle } = slice.actions;

// ----------------------------------------------------------------------

export function getVehicleList() {
  const accessToken = window.localStorage.getItem('accessToken');
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const query = await fetch(`${generalConfig.baseUrl}/api/v1/vehicles`, {
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      });
      const response = await query.json();

      dispatch(slice.actions.setVehicleList(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export const addVehicle = (values) => async (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken');
  dispatch(slice.actions.startLoading());
  try {
    const query = await fetch(
      `${generalConfig.baseUrl}/api/vehicle/createvehicle`,
      {
        method: 'POST',
        body: JSON.stringify(values),
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      }
    );
    const response = await query.json();
    if (response.message === 'error') {
      return response;
    }
    dispatch(slice.actions.addVehicleSuccess(response.body));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};

// ----------------------------------------------------------------------

export const updateVehicle = (values) => async (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken');
  dispatch(slice.actions.startLoading());
  try {
    const query = await fetch(
      `${generalConfig.baseUrl}/api/v1/actualizar_vehiculo`,
      {
        method: 'PUT',
        body: JSON.stringify(values),
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      }
    );
    const response = await query.json();
    if (response.message === 'error') {
      dispatch(slice.action.endLoading());
      return response;
    }
    dispatch(slice.actions.updateVehicleSuccess(response.body));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};

// ----------------------------------------------------------------------

export const removeVehicle = (id) => async (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken');
  dispatch(slice.actions.startLoading());
  try {
    const query = await fetch(
      `${generalConfig.baseUrl}/api/vehicle/deletevehicle/${id}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      }
    );
    const response = await query.json();
    if (response.message === 'error') {
      return response;
    }
    dispatch(slice.actions.removeVehicleSuccess(id));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};

// ----------------------------------------------------------------------

export function getVehicle(id_interno) {
  const accessToken = window.localStorage.getItem('accessToken');
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const query = await fetch(
        `${generalConfig.baseUrl}/api/v1/vehicle/${id_interno}`,
        {
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${accessToken}`
          }
        }
      );
      const response = await query.json();
      return response;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
