import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { firestoreReducer } from 'redux-firestore';
import { firebaseReducer } from 'react-redux-firebase';
import notificationsReducer from './slices/notifications';
import authJwtReducer from './slices/authJwt';
import settingsReducer from './slices/settings';
import taskReducer from './slices/task';
import maintenanceReducer from './slices/maintenance';
import materialReducer from './slices/material';
import workerReducer from './slices/worker';
import externalWorkerReducer from './slices/externalWorker';
import vehicleReducer from './slices/vehicle';
import guideReducer from './slices/guide';
import categoryReducer from './slices/category';
import noveltyReducer from './slices/novelty';
import bitacoraReducer from './slices/bitacora';
import motiveReducer from './slices/motive';
import indicatorReducer from './slices/indicator';
import productReducer from './slices/product';
import petitionReducer from './slices/petition';
import modelReducer from './slices/model';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage: storage,
  keyPrefix: 'redux-',
  whitelist: ['settings']
};

const authPersistConfig = {
  key: 'authJwt',
  storage: storage,
  keyPrefix: 'redux-',
  whitelist: ['isAuthenticated']
};

const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  notification: notificationsReducer,
  settings: settingsReducer,
  authJwt: persistReducer(authPersistConfig, authJwtReducer),
  task: taskReducer,
  maintenance: maintenanceReducer,
  material: materialReducer,
  worker: workerReducer,
  externalWorker: externalWorkerReducer,
  vehicle: vehicleReducer,
  guide: guideReducer,
  category: categoryReducer,
  novelty: noveltyReducer,
  bitacora: bitacoraReducer,
  motive: motiveReducer,
  indicator: indicatorReducer,
  product: productReducer,
  petition: petitionReducer,
  model: modelReducer
});

export { rootPersistConfig, rootReducer };
