import { createSlice } from '@reduxjs/toolkit';
import { generalConfig } from '../../config';

const initialState = {
  isLoading: false,
  error: false,
  novelty: null,
  selectedNovelty: null,
  noveltyList: [],
  noveltyPendingList: [],
  noveltyByBitacoraList: []
};

const slice = createSlice({
  name: 'novelty',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setNoveltyList(state, action) {
      state.isLoading = false;
      state.noveltyList = action.payload;
    },
    addNoveltySuccess(state, action) {
      state.isLoading = false;
      state.noveltyList = [...state.noveltyList, action.payload];
    },
    setSelectedNovelty(state, action) {
      state.isLoading = false;
      const id = action.payload;
      const index = state.noveltyList.findIndex((x) => x._id === id);
      const selected = state.noveltyList[index];
      state.selectedNovelty = selected;
    },
    updateNovelty(state, action) {
      const updatedNovelty = action.payload;

      const copyList = state.noveltyList;

      const index = copyList.findIndex(
        (x) => x.id_interno === updatedNovelty.id_interno
      );

      copyList[index] = updatedNovelty;

      state.noveltyList = copyList;
    },
    removeNovelty(state, action) {
      const id_interno = action.payload;

      const copyList = state.noveltyList;

      const index = copyList.findIndex((x) => x.id_interno === id_interno);

      copyList.splice(index, 1);

      state.noveltyList = copyList;
    },

    setNoveltyPendingList(state, action) {
      state.isLoading = false;
      state.noveltyPendingList = action.payload;
    },
    setNoveltyByBitacora(state, action) {
      state.isLoading = false;
      state.noveltyByBitacoraList = action.payload;
    },
    setNoveltyToBitacora(state, action) {
      state.isLoading = false;
      const novelty = action.payload;
      state.noveltyByBitacoraList = [...state.noveltyByBitacoraList, novelty];
    },
    removeNoveltyInBitacora(state, action) {
      const id_interno = action.payload;

      const copyList = state.noveltyByBitacoraList;

      const index = copyList.findIndex((x) => x.id_interno === id_interno);

      copyList.splice(index, 1);

      state.noveltyByBitacoraList = copyList;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { setSelectedNovelty } = slice.actions;

// ----------------------------------------------------------------------

export function getNoveltyList(id_vehiculo) {
  const accessToken = window.localStorage.getItem('accessToken');

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const query = await fetch(
        `${generalConfig.baseUrl}/api/v1/novedades_por_vehiculo/${id_vehiculo}`,
        {
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${accessToken}`
          }
        }
      );
      const response = await query.json();

      dispatch(slice.actions.setNoveltyList(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export const addNovelty = (values) => async (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken');
  dispatch(slice.actions.startLoading());
  try {
    const query = await fetch(
      `${generalConfig.baseUrl}/api/v1/agregar_novedad_a_vehiculo`,
      {
        method: 'POST',
        body: JSON.stringify(values),
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      }
    );
    const response = await query.json();

    if (response.message === 'error') {
      return response;
    }
    dispatch(slice.actions.addNoveltySuccess(response.body));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};

// ----------------------------------------------------------------------

export const updateNovelty = (values) => async (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken');
  dispatch(slice.actions.startLoading());
  try {
    const query = await fetch(
      `${generalConfig.baseUrl}/api/v1/editar_novedad`,
      {
        method: 'PUT',
        body: JSON.stringify(values),
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      }
    );
    const response = await query.json();

    if (response.message === 'error') {
      return response;
    }
    const updatedNovelty = {
      ...values,
      ...response.body
    };
    dispatch(slice.actions.updateNovelty(updatedNovelty));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};

// ----------------------------------------------------------------------

export const removeNovelty = (id_interno) => async (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken');
  dispatch(slice.actions.startLoading());
  try {
    const query = await fetch(
      `${generalConfig.baseUrl}/api/v1/eliminar_novedad/${id_interno}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      }
    );
    const response = await query.json();
    if (response.message === 'error') {
      return response;
    }
    dispatch(slice.actions.removeNovelty(id_interno));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};

// ----------------------------------------------------------------------

export function getNoveltyPendingList(id_vehiculo) {
  const accessToken = window.localStorage.getItem('accessToken');
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const query = await fetch(
        `${generalConfig.baseUrl}/api/v1/novedades_por_vehiculo_pendientes/${id_vehiculo}`,
        {
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${accessToken}`
          }
        }
      );
      const response = await query.json();

      dispatch(slice.actions.setNoveltyPendingList(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getNoveltyByBitacora(id_interno) {
  const accessToken = window.localStorage.getItem('accessToken');
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const query = await fetch(
        `${generalConfig.baseUrl}/api/v1/novedades_por_bitacora/${id_interno}`,
        {
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${accessToken}`
          }
        }
      );
      const response = await query.json();

      dispatch(slice.actions.setNoveltyByBitacora(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function addNoveltyToBitacora(values) {
  const accessToken = window.localStorage.getItem('accessToken');
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const query = await fetch(
        `${generalConfig.baseUrl}/api/v1/agregar_novedad_a_bitacora/`,
        {
          method: 'PUT',
          body: JSON.stringify({
            id_novelty: values.novelty.id_interno,
            id_bitacora: values.id_bitacora
          }),
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${accessToken}`
          }
        }
      );
      const response = await query.json();

      if (response.message === 'error') {
        return response;
      }

      dispatch(slice.actions.setNoveltyToBitacora(values.novelty));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export const removeNoveltyInBitacora = (id_interno) => async (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken');
  dispatch(slice.actions.startLoading());
  try {
    const query = await fetch(
      `${generalConfig.baseUrl}/api/v1/eliminar_novedad_en_bitacora/${id_interno}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      }
    );
    const response = await query.json();
    if (response.message === 'error') {
      return response;
    }
    dispatch(slice.actions.removeNoveltyInBitacora(id_interno));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};
