import { createSlice } from '@reduxjs/toolkit';
import { generalConfig } from '../../config';

const initialState = {
  isLoading: false,
  error: false,
  task: null,
  selectedTask: null,
  taskList: [],
  taskByGuideList: [],
  taskVisibleList: []
};

const slice = createSlice({
  name: 'task',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    endLoading(state) {
      state.isLoading = false;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setTask(state, action) {
      state.isLoading = false;
      state.taskList = [...state.taskList, action.payload];
    },
    setTaskList(state, action) {
      state.isLoading = false;
      state.taskList = action.payload;
    },
    setSelectedTask(state, action) {
      state.isLoading = false;
      const id = action.payload;
      const index = state.taskList.findIndex((x) => x._id === id);
      const selected = state.taskList[index];
      state.selectedTask = selected;
    },
    updateTask(state, action) {
      const updatedTask = action.payload;

      const copyList = state.taskList;

      const index = copyList.findIndex(
        (x) => x.id_interno === updatedTask.id_interno
      );

      copyList[index] = updatedTask;

      state.taskList = copyList;
    },
    removeTask(state, action) {
      const id_interno = action.payload;

      const copyList = state.taskList;

      const index = copyList.findIndex((x) => x.id_interno === id_interno);

      copyList.splice(index, 1);

      state.taskList = copyList;
    },

    setTaskByGuideList(state, action) {
      state.isLoading = false;
      state.taskByGuideList = action.payload;
    },
    setTaskGuide(state, action) {
      state.isLoading = false;
      state.taskByGuideList = [...state.taskByGuideList, action.payload];
    },
    removeTaskGuide(state, action) {
      state.isLoading = false;
      const id_interno = action.payload;

      const copyList = state.taskByGuideList;

      const index = copyList.findIndex((x) => x.id_interno === id_interno);

      copyList.splice(index, 1);

      state.taskByGuideList = copyList;
    },

    setTaskVisibleList(state, action) {
      state.isLoading = false;
      state.taskVisibleList = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { setSelectedTask } = slice.actions;

// ----------------------------------------------------------------------

export function getTaskList() {
  const accessToken = window.localStorage.getItem('accessToken');
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const query = await fetch(`${generalConfig.baseUrl}/api/v1/tareas`, {
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      });
      const response = await query.json();

      dispatch(slice.actions.setTaskList(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export const addTask = (values) => async (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken');
  dispatch(slice.actions.startLoading());
  try {
    const query = await fetch(`${generalConfig.baseUrl}/api/v1/agregar_tarea`, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${accessToken}`
      }
    });
    const response = await query.json();

    if (response.message === 'error') {
      return response;
    }
    const newTask = {
      ...values,
      id_interno: response.body
    };
    dispatch(slice.actions.setTask(newTask));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};

// ----------------------------------------------------------------------

export const updateTask = (values) => async (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken');
  dispatch(slice.actions.startLoading());
  try {
    const query = await fetch(`${generalConfig.baseUrl}/api/v1/editar_tarea`, {
      method: 'PUT',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${accessToken}`
      }
    });
    const response = await query.json();

    if (response.message === 'error') {
      dispatch(slice.actions.endLoading());
      return response;
    }
    const updatedTask = {
      ...values
    };
    dispatch(slice.actions.updateTask(updatedTask));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};

// ----------------------------------------------------------------------

export const removeTask = (id_interno) => async (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken');
  dispatch(slice.actions.startLoading());
  try {
    const query = await fetch(
      `${generalConfig.baseUrl}/api/v1/eliminar_tarea/${id_interno}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      }
    );
    const response = await query.json();
    if (response.message === 'error') {
      return response;
    }
    dispatch(slice.actions.removeTask(id_interno));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};

// ----------------------------------------------------------------------

export function getTaskByGuideList(id_interno) {
  const accessToken = window.localStorage.getItem('accessToken');
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const query = await fetch(
        `${generalConfig.baseUrl}/api/v1/tareas_por_pauta/${id_interno}`,
        {
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${accessToken}`
          }
        }
      );
      const response = await query.json();

      dispatch(slice.actions.setTaskByGuideList(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getTaskVisibleList() {
  const accessToken = window.localStorage.getItem('accessToken');
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const query = await fetch(
        `${generalConfig.baseUrl}/api/v1/tareas_para_pauta/`,
        {
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${accessToken}`
          }
        }
      );
      const response = await query.json();

      dispatch(slice.actions.setTaskVisibleList(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function addTaskGuide(values) {
  // console.log('valores antes de enviar una tarea en la guía', values);
  const accessToken = window.localStorage.getItem('accessToken');
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const query = await fetch(
        `${generalConfig.baseUrl}/api/v1/agregar_tarea_pauta/`,
        {
          method: 'POST',
          body: JSON.stringify({
            id_tarea: values.id_tarea,
            id_eme: values.id_eme
          }),
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${accessToken}`
          }
        }
      );
      const response = await query.json();
      console.log('response');

      if (response.message === 'error') {
        return response;
      }

      const newTask = {
        id_interno: response.body,
        id_eme: values.id_eme,
        id_tarea: values.id_tarea,
        descripcion: values.task.descripcion,
        tiempo_hh: values.task.tiempo_hh
      };

      dispatch(slice.actions.setTaskGuide(newTask));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export const removeTaskGuide = (id_interno) => async (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken');
  dispatch(slice.actions.startLoading());
  try {
    const query = await fetch(
      `${generalConfig.baseUrl}/api/v1/eliminar_tarea_pauta/${id_interno}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      }
    );
    const response = await query.json();
    if (response.message === 'error') {
      return response;
    }
    dispatch(slice.actions.removeTaskGuide(id_interno));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};
