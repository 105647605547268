import React from 'react';
import PropTypes from 'prop-types';
import useAuth from 'src/hooks/useAuth';
import { Redirect } from 'react-router-dom';
import { PATH_APP } from 'src/routes/paths';
import LoadingScreen from 'src/components/LoadingScreen';

// ----------------------------------------------------------------------

OperationProtect.propTypes = {
  children: PropTypes.node
};

function OperationProtect({ children }) {
  const { isLoading, user } = useAuth();

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (Object.keys(user).length !== 0 && user.rol !== 'o') {
    console.log('entre porque es usuario no es de operaciones');
    return <Redirect to={PATH_APP.root} />;
  }

  return <>{children}</>;
}

export default OperationProtect;
