import { createSlice } from '@reduxjs/toolkit';
import { generalConfig } from '../../config';
import { map } from 'lodash';

const initialState = {
  isLoading: false,
  error: false,
  model: null,
  selectedModel: null,
  modelList: [],
  scheduleListByModel: []
};

const slice = createSlice({
  name: 'model',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    endLoading(state) {
      state.isLoading = false;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setModelList(state, action) {
      state.isLoading = false;
      state.modelList = action.payload;
    },
    setModel(state, action) {
      state.isLoading = false;
      state.modelList = [...state.modelList, action.payload];
    },
    setSelectedModel(state, action) {
      state.isLoading = false;
      const id = action.payload;
      const index = state.modelList.findIndex((x) => x._id === id);
      const selected = state.modelList[index];
      state.selectedModel = selected;
    },
    updateModel(state, action) {
      const updatedModel = action.payload;

      const copyList = state.modelList;

      const index = copyList.findIndex(
        (x) => x.id_interno === updatedModel.id_interno
      );

      copyList[index] = updatedModel;

      state.modelList = copyList;
    },
    removeModel(state, action) {
      const id_interno = action.payload;

      const copyList = state.modelList;

      const index = copyList.findIndex((x) => x.id_interno === id_interno);

      copyList.splice(index, 1);

      state.modelList = copyList;
    },
    setScheduleListByModel(state, action) {
      state.isLoading = false;
      state.scheduleListByModel = action.payload;
    },
    updateScheduleInModelSuccess(state, action) {
      state.isLoading = false;
      const schedule = action.payload;
      const copyList = state.scheduleListByModel;
      const updatedList = map(copyList, (item) => {
        if (item.id_interno === schedule.id_interno) {
          return {
            ...item,
            id_pauta: schedule.id_pauta,
            pauta: schedule.pauta
          };
        }
        return item;
      });
      state.scheduleListByModel = updatedList;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { setSelectedModel } = slice.actions;

// ----------------------------------------------------------------------

export function getModelList() {
  // const accessToken = window.localStorage.getItem('accessToken');
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const query = await fetch(`${generalConfig.baseUrl}/api/v1/modelos`);
      const response = await query.json();

      dispatch(slice.actions.setModelList(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export const addModel = (values) => async (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken');
  dispatch(slice.actions.startLoading());
  try {
    const query = await fetch(`${generalConfig.baseUrl}/api/v1/agregar_pauta`, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${accessToken}`
      }
    });
    const response = await query.json();

    if (response.message === 'error') {
      return response;
    }
    const newModel = {
      ...values,
      id_interno: response.body
    };
    dispatch(slice.actions.setModel(newModel));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};

// ----------------------------------------------------------------------

export const updateModel = (values) => async (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken');
  dispatch(slice.actions.startLoading());
  try {
    const query = await fetch(`${generalConfig.baseUrl}/api/v1/editar_pauta`, {
      method: 'PUT',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${accessToken}`
      }
    });
    const response = await query.json();

    if (response.message === 'error') {
      return response;
    }
    const updatedModel = {
      ...values
    };
    dispatch(slice.actions.updateModel(updatedModel));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};

// ----------------------------------------------------------------------

export const removeModel = (id_interno) => async (dispatch) => {
  // const accessToken = window.localStorage.getItem('accessToken');
  dispatch(slice.actions.startLoading());
  try {
    const query = await fetch(
      `${generalConfig.baseUrl}/api/v1/eliminar_pauta/${id_interno}`,
      {
        method: 'DELETE'
      }
    );
    const response = await query.json();
    if (response.message === 'error') {
      return response;
    }
    dispatch(slice.actions.removeModel(id_interno));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};

// ----------------------------------------------------------------------

export function getPautaByPDF(id_interno) {
  // const accessToken = window.localStorage.getItem('accessToken');
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const query = await fetch(
        `${generalConfig.baseUrl}/api/v1/pauta_pdf/${id_interno}`
      );
      const response = await query.json();
      console.log(response);

      dispatch(slice.actions.setPdfByModel(response.pdf));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------SCHEDULE BY GUIDE

export function getScheduleListByModel(id_modelo) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const query = await fetch(
        `${generalConfig.baseUrl}/api/v1/cronogramas_por_modelo/${id_modelo}`
      );
      const response = await query.json();

      dispatch(slice.actions.setScheduleListByModel(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export const addScheduleByModel = (values) => async (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken');
  const initial = parseInt(values.initial);
  const intervalo = parseInt(values.interval);
  const startInterval = parseInt(values.startInterval);
  const id_modelo = values.id_model;
  let multipleArray = [];

  if (initial) {
    multipleArray.push([initial, id_modelo]);
  }

  async function doArray() {
    for (let index = startInterval; index <= 4000000; index += intervalo) {
      multipleArray.push([index, id_modelo]);
    }
    return;
  }

  async function sendData() {
    await doArray();

    dispatch(slice.actions.startLoading());
    try {
      const query = await fetch(
        `${generalConfig.baseUrl}/api/v1/agregar_cronograma_a_modelo`,
        {
          method: 'POST',
          body: JSON.stringify({
            multipleArray: multipleArray,
            id_modelo: id_modelo
          }),
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${accessToken}`
          }
        }
      );
      const response = await query.json();

      if (response.message === 'error') {
        dispatch(slice.actions.endLoading());
        return response;
      }
      dispatch(slice.actions.setScheduleListByModel(response.body));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  }
  sendData();
};

// ----------------------------------------------------------------------

export const updateScheduleInModel = (schedule) => async (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken');
  dispatch(slice.actions.startLoading());
  try {
    const query = await fetch(
      `${generalConfig.baseUrl}/api/v1/actualizar_cronograma_en_modelo`,
      {
        method: 'PUT',
        body: JSON.stringify({ schedule: schedule }),
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      }
    );
    const response = await query.json();

    if (response.message === 'error') {
      dispatch(slice.actions.endLoading());
      return response;
    }
    dispatch(slice.actions.updateScheduleInModelSuccess(schedule));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};

// ----------------------------------------------------------------------

export const removeScheduleByModel = (id_modelo) => async (dispatch) => {
  dispatch(slice.actions.startLoading());
  try {
    const query = await fetch(
      `${generalConfig.baseUrl}/api/v1/eliminar_cronogramas_en_modelo/${id_modelo}`,
      {
        method: 'DELETE'
      }
    );
    const response = await query.json();
    if (response.message === 'error') {
      dispatch(slice.actions.endLoading());
      return response;
    }
    dispatch(slice.actions.setScheduleListByModel([]));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};
