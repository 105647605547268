import { createSlice } from '@reduxjs/toolkit';
import { generalConfig } from '../../config';

const initialState = {
  isLoading: false,
  error: false,
  externalWorkerList: []
};

const slice = createSlice({
  name: 'externalWorker',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setExternalWorkerList(state, action) {
      state.isLoading = false;
      state.externalWorkerList = action.payload;
    },
    addExternalWorkerSuccess(state, action) {
      state.isLoading = false;
      state.externalWorkerList = [...state.externalWorkerList, action.payload];
    },
    setSelectedExternalWorker(state, action) {
      state.isLoading = false;
      const id = action.payload;
      const index = state.externalWorkerList.findIndex((x) => x._id === id);
      const selected = state.externalWorkerList[index];
      state.selectedExternalWorker = selected;
    },
    updateExternalWorkerSuccess(state, action) {
      const externalWorker = action.payload;

      const copyList = state.externalWorkerList;

      const index = copyList.findIndex((x) => x._id === externalWorker._id);

      copyList[index] = externalWorker;

      state.externalWorkerList = copyList;
    },
    removeExternalWorkerSuccess(state, action) {
      const id = action.payload;

      const copyList = state.externalWorkerList;

      const index = copyList.findIndex((x) => x._id === id);

      copyList.splice(index, 1);

      state.externalWorkerList = copyList;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { setSelectedExternalWorker } = slice.actions;

// ----------------------------------------------------------------------

export function getExternalWorkerList() {
  const accessToken = window.localStorage.getItem('accessToken');
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const query = await fetch(
        `${generalConfig.baseUrl}/api/v1/personal_externo`,
        {
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${accessToken}`
          }
        }
      );
      const response = await query.json();

      dispatch(slice.actions.setExternalWorkerList(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export const addExternalWorker = (values) => async (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken');
  dispatch(slice.actions.startLoading());
  try {
    const query = await fetch(
      `${generalConfig.baseUrl}/api/externalWorker/createexternalWorker`,
      {
        method: 'POST',
        body: JSON.stringify(values),
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      }
    );
    const response = await query.json();
    if (response.message === 'error') {
      return response;
    }
    dispatch(slice.actions.addExternalWorkerSuccess(response.body));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};

// ----------------------------------------------------------------------

export const updateExternalWorker = (values) => async (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken');
  dispatch(slice.actions.startLoading());
  try {
    const query = await fetch(
      `${generalConfig.baseUrl}/api/externalWorker/updateexternalWorker/${values._id}`,
      {
        method: 'PUT',
        body: JSON.stringify(values),
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      }
    );
    const response = await query.json();
    if (response.message === 'error') {
      return response;
    }
    dispatch(slice.actions.updateExternalWorkerSuccess(response.body));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};

// ----------------------------------------------------------------------

export const removeExternalWorker = (id) => async (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken');
  dispatch(slice.actions.startLoading());
  try {
    const query = await fetch(
      `${generalConfig.baseUrl}/api/externalWorker/deleteexternalWorker/${id}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      }
    );
    const response = await query.json();
    if (response.message === 'error') {
      return response;
    }
    dispatch(slice.actions.removeExternalWorkerSuccess(id));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};

// ----------------------------------------------------------------------
