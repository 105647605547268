import { createSlice } from '@reduxjs/toolkit';
import { generalConfig } from '../../config';

const initialState = {
  isLoading: false,
  error: false,
  workerList: [],
  workerListAutocomplete: []
};

const slice = createSlice({
  name: 'worker',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setWorkerList(state, action) {
      state.isLoading = false;
      state.workerList = action.payload;
    },
    addWorkerSuccess(state, action) {
      state.isLoading = false;
      state.workerList = [...state.workerList, action.payload];
    },
    setSelectedWorker(state, action) {
      state.isLoading = false;
      const id = action.payload;
      const index = state.workerList.findIndex((x) => x._id === id);
      const selected = state.workerList[index];
      state.selectedWorker = selected;
    },
    updateWorkerSuccess(state, action) {
      const worker = action.payload;

      const copyList = state.workerList;

      const index = copyList.findIndex((x) => x._id === worker._id);

      copyList[index] = worker;

      state.workerList = copyList;
    },
    removeWorkerSuccess(state, action) {
      const id = action.payload;

      const copyList = state.workerList;

      const index = copyList.findIndex((x) => x._id === id);

      copyList.splice(index, 1);

      state.workerList = copyList;
    },
    setWorkerListAutocomplete(state, action) {
      state.isLoading = false;
      state.workerListAutocomplete = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { setSelectedWorker } = slice.actions;

// ----------------------------------------------------------------------

export function getWorkerList() {
  const accessToken = window.localStorage.getItem('accessToken');
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const query = await fetch(
        `${generalConfig.baseUrl}/api/v1/personal_interno`,
        {
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${accessToken}`
          }
        }
      );
      const response = await query.json();

      dispatch(slice.actions.setWorkerList(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export const addWorker = (values) => async (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken');
  dispatch(slice.actions.startLoading());
  try {
    const query = await fetch(
      `${generalConfig.baseUrl}/api/worker/createworker`,
      {
        method: 'POST',
        body: JSON.stringify(values),
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      }
    );
    const response = await query.json();
    if (response.message === 'error') {
      return response;
    }
    dispatch(slice.actions.addWorkerSuccess(response.body));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};

// ----------------------------------------------------------------------

export const updateWorker = (values) => async (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken');
  dispatch(slice.actions.startLoading());
  try {
    const query = await fetch(
      `${generalConfig.baseUrl}/api/worker/updateworker/${values._id}`,
      {
        method: 'PUT',
        body: JSON.stringify(values),
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      }
    );
    const response = await query.json();
    if (response.message === 'error') {
      return response;
    }
    dispatch(slice.actions.updateWorkerSuccess(response.body));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};

// ----------------------------------------------------------------------

export const removeWorker = (id) => async (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken');
  dispatch(slice.actions.startLoading());
  try {
    const query = await fetch(
      `${generalConfig.baseUrl}/api/worker/deleteworker/${id}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      }
    );
    const response = await query.json();
    if (response.message === 'error') {
      return response;
    }
    dispatch(slice.actions.removeWorkerSuccess(id));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};

// ----------------------------------------------------------------------

export function getWorkerListAutocomplete() {
  const accessToken = window.localStorage.getItem('accessToken');
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    let data = {};
    //Interno
    try {
      const query = await fetch(
        `${generalConfig.baseUrl}/api/v1/personal_interno`,
        {
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${accessToken}`
          }
        }
      );
      const response = await query.json();

      const workerList = response.map((item) => {
        return {
          ...item,
          tipo: 'INTERNO'
        };
      });

      data = [...workerList];
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
    //Externo
    try {
      const query = await fetch(
        `${generalConfig.baseUrl}/api/v1/personal_externo`,
        {
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${accessToken}`
          }
        }
      );
      const response = await query.json();

      const workerList = response.map((item) => {
        return {
          ...item,
          tipo: 'EXTERNO'
        };
      });

      data = [...data, ...workerList];
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }

    dispatch(slice.actions.setWorkerListAutocomplete(data));
  };
}

// ----------------------------------------------------------------------
