import NProgress from 'nprogress';
import { PATH_PAGE, PATH_APP, PATH_OPERATION } from './paths';
import LoadingScreen from 'src/components/LoadingScreen';
import { Switch, Route, Redirect, HashRouter } from 'react-router-dom';
import React, { Suspense, Fragment, lazy, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GuestProtect from 'src/components/Auth/GuestProtect';
import AuthProtect from 'src/components/Auth/AuthProtect';
import MaintenanceProtect from 'src/components/Auth/MaintenanceProtect';
import DashboardLayout from 'src/layouts/DashboardLayout';

import OperationProtect from 'src/components/Auth/OperationProtect';
import OperationLayout from 'src/layouts/OperationLayout';

// ----------------------------------------------------------------------

const nprogressStyle = makeStyles((theme) => ({
  '@global': {
    '#nprogress': {
      pointerEvents: 'none',
      '& .bar': {
        top: 0,
        left: 0,
        height: 2,
        width: '100%',
        position: 'fixed',
        zIndex: theme.zIndex.snackbar,
        backgroundColor: theme.palette.primary.main,
        boxShadow: `0 0 2px ${theme.palette.primary.main}`
      },
      '& .peg': {
        right: 0,
        opacity: 1,
        width: 100,
        height: '100%',
        display: 'block',
        position: 'absolute',
        transform: 'rotate(3deg) translate(0px, -4px)',
        boxShadow: `0 0 10px ${theme.palette.primary.main}, 0 0 5px ${theme.palette.primary.main}`
      }
    }
  }
}));

function RouteProgress(props) {
  nprogressStyle();

  NProgress.configure({
    speed: 500,
    showSpinner: false
  });

  useEffect(() => {
    NProgress.done();
    return () => {
      NProgress.start();
    };
  }, []);

  return <Route {...props} />;
}

export function renderRoutes(routes = []) {
  return (
    <HashRouter>
      <Suspense fallback={<LoadingScreen />}>
        <Switch>
          {routes.map((route, i) => {
            const Component = route.component;
            const Layout = route.layout || Fragment;
            const Guard = route.guard || Fragment;
            const GuardRol = route.guardRol || Fragment;

            return (
              <RouteProgress
                key={i}
                path={route.path}
                exact={route.exact}
                render={(props) => (
                  <Guard>
                    <GuardRol>
                      <Layout>
                        {route.routes ? (
                          renderRoutes(route.routes)
                        ) : (
                          <Component {...props} />
                        )}
                      </Layout>
                    </GuardRol>
                  </Guard>
                )}
              />
            );
          })}
        </Switch>
      </Suspense>
    </HashRouter>
  );
}

const routes = [
  {
    exact: true,
    guard: GuestProtect,
    path: PATH_PAGE.auth.login,
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    path: PATH_PAGE.auth.loginUnprotected,
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    path: PATH_PAGE.comingSoon,
    component: lazy(() => import('src/views/pages/ComingSoonView'))
  },
  {
    exact: true,
    path: PATH_PAGE.maintenance,
    component: lazy(() => import('src/views/pages/MaintenanceView'))
  },
  {
    exact: true,
    path: PATH_PAGE.payment,
    component: lazy(() => import('src/views/pages/PaymentView'))
  },
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/Page404View'))
  },
  {
    exact: true,
    path: '/500',
    component: lazy(() => import('src/views/errors/Page500View'))
  },
  {
    exact: true,
    path: PATH_PAGE.auth.root,
    component: () => <Redirect to={PATH_PAGE.auth.login} />
  },
  //PROTECT AUTH
  //PROTECT OPERATION
  // ----------------------------------------------------------------------
  {
    exact: true,
    path: PATH_OPERATION.root,
    guard: AuthProtect,
    guardRol: OperationProtect,
    layout: OperationLayout,
    component: lazy(() => import('src/views/operation/WelcomeViews'))
  },
  {
    exact: true,
    path: PATH_OPERATION.binnacles,
    guard: AuthProtect,
    guardRol: OperationProtect,
    layout: OperationLayout,
    component: lazy(() => import('src/views/operation/BinnacleListViews'))
  },
  {
    exact: true,
    path: PATH_OPERATION.root,
    component: () => <Redirect to={PATH_OPERATION.root} />
  },
  //PROTECT MAINTENANCE
  // ----------------------------------------------------------------------
  //VEHICLES
  {
    exact: true,
    path: PATH_APP.vehicle.vehicles,
    guard: AuthProtect,
    guardRol: MaintenanceProtect,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/app/VehicleListViews'))
  },
  {
    exact: true,
    path: PATH_APP.vehicle.models,
    guard: AuthProtect,
    guardRol: MaintenanceProtect,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/app/ModelListViews'))
  },
  {
    exact: true,
    path: PATH_APP.vehicle.root,
    component: () => <Redirect to={PATH_APP.vehicle.root} />
  },
  //END VEHICLES
  //MAINTENANCE
  {
    exact: true,
    path: PATH_APP.maintenance.maintenances,
    guard: AuthProtect,
    guardRol: MaintenanceProtect,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/app/MaintenanceListViews'))
  },
  {
    exact: true,
    path: PATH_APP.maintenance.guides,
    guard: AuthProtect,
    guardRol: MaintenanceProtect,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/app/GuideListViews'))
  },
  {
    exact: true,
    path: PATH_APP.maintenance.tasks,
    guard: AuthProtect,
    guardRol: MaintenanceProtect,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/app/TaskListViews'))
  },
  {
    exact: true,
    path: PATH_APP.maintenance.materials,
    guard: AuthProtect,
    guardRol: MaintenanceProtect,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/app/MaterialListViews'))
  },
  {
    exact: true,
    path: PATH_APP.maintenance.root,
    component: () => <Redirect to={PATH_APP.maintenance.root} />
  },
  //END MAINTENANCE
  {
    exact: true,
    path: PATH_APP.root,
    guard: AuthProtect,
    guardRol: MaintenanceProtect,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/app/WelcomeViews'))
  },
  {
    exact: true,
    path: PATH_APP.profile,
    guard: AuthProtect,
    guardRol: MaintenanceProtect,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/app/ProfileViews'))
  },
  {
    exact: true,
    path: PATH_APP.indicators,
    guard: AuthProtect,
    guardRol: MaintenanceProtect,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/app/IndicatorViews'))
  },
  {
    exact: true,
    path: PATH_APP.root,
    component: () => <Redirect to={PATH_APP.root} />
  },
  // ----------------------------------------------------------------------
  {
    component: () => <Redirect to="/404" />
  }
];

export default routes;
