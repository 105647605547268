import { createSlice } from '@reduxjs/toolkit';
import { generalConfig } from '../../config';
import { map } from 'lodash';

const initialState = {
  isLoading: false,
  error: false,
  petitionList: []
};

const slice = createSlice({
  name: 'petition',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    endLoading(state) {
      state.isLoading = false;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setPetitionList(state, action) {
      state.isLoading = false;
      state.petitionList = action.payload;
    },
    updatePetitionSuccess(state, action) {
      state.isLoading = false;
      const id_interno = action.payload.id_interno;
      const estado = action.payload.estado;
      const copyList = state.petitionList;

      const updatedList = map(copyList, (item) => {
        if (item.id_interno === id_interno) {
          return {
            ...item,
            estado_permiso: estado
          };
        }
        return item;
      });

      state.petitionList = updatedList;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
// export const {} = slice.actions;

// ----------------------------------------------------------------------

export function getPetitionList() {
  const accessToken = window.localStorage.getItem('accessToken');
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const query = await fetch(`${generalConfig.baseUrl}/api/v1/peticiones`, {
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      });
      const response = await query.json();

      dispatch(slice.actions.setPetitionList(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export const updatePetition = (
  id_interno,
  uid,
  estado,
  id_ot,
  folio,
  id_empleado
) => async (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken');
  dispatch(slice.actions.startLoading());
  try {
    const query = await fetch(
      `${generalConfig.baseUrl}/api/v1/aprobar_peticion`,
      {
        method: 'PUT',
        body: JSON.stringify({
          id_interno: id_interno,
          id_usuario: uid,
          estado: estado,
          id_ot: id_ot,
          folio: folio,
          id_empleado: id_empleado
        }),
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      }
    );
    const response = await query.json();
    console.log('response al aceptar petición', response);
    if (response.message === 'error') {
      dispatch(slice.actions.endLoading());
      return response;
    }
    dispatch(
      slice.actions.updatePetitionSuccess({ id_interno: id_interno, estado: 1 })
    );
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};

// ----------------------------------------------------------------------

export const rejectPetition = (id_interno, uid, estado, id_ot, folio) => async (
  dispatch
) => {
  const accessToken = window.localStorage.getItem('accessToken');
  dispatch(slice.actions.startLoading());
  try {
    const query = await fetch(
      `${generalConfig.baseUrl}/api/v1/rechazar_peticion`,
      {
        method: 'PUT',
        body: JSON.stringify({
          id_interno: id_interno,
          id_usuario: uid,
          estado: estado,
          id_ot: id_ot,
          folio: folio
        }),
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      }
    );
    const response = await query.json();
    console.log('response al rechazar petición', response);
    if (response.message === 'error') {
      dispatch(slice.actions.endLoading());
      return response;
    }
    dispatch(
      slice.actions.updatePetitionSuccess({ id_interno: id_interno, estado: 2 })
    );
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};
