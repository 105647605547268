import { createSlice } from '@reduxjs/toolkit';
import { generalConfig } from '../../config';

const initialState = {
  isLoading: false,
  error: false,
  vehicleList: []
};

const slice = createSlice({
  name: 'indicadores',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    endLoading(state) {
      state.isLoading = false;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setVehicleList(state, action) {
      state.isLoading = false;
      state.vehicleList = action.payload;
    },
    updateVehicle(state, action) {
      state.isLoading = false;
      const updatedVehicle = action.payload;
      state.vehicleList = state.vehicleList.map((item) => {
        if (item.id_interno === updatedVehicle.id_interno) {
          return {
            ...updatedVehicle
          };
        }
        return item;
      });
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
// export const {} = slice.actions;

// ----------------------------------------------------------------------

export const getIndicators = (values) => async (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken');
  dispatch(slice.actions.startLoading());
  try {
    const query = await fetch(`${generalConfig.baseUrl}/api/v1/indicadores`, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${accessToken}`
      }
    });
    const response = await query.json();
    dispatch(slice.actions.endLoading());
    return response;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};

// ----------------------------------------------------------------------

export const getVirtualBoard = () => async (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken');
  dispatch(slice.actions.startLoading());
  try {
    const query = await fetch(
      `${generalConfig.baseUrl}/api/v1/pizarra_virtual`,
      {
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      }
    );
    const response = await query.json();
    dispatch(slice.actions.setVehicleList(response));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};

// ----------------------------------------------------------------------

export const addKilometer = (values) => async (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken');
  dispatch(slice.actions.startLoading());
  try {
    const query = await fetch(
      `${generalConfig.baseUrl}/api/v1/agregar_kilometraje_pizarra`,
      {
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${accessToken}`
        },
        method: 'POST',
        body: JSON.stringify(values)
      }
    );
    const response = await query.json();
    dispatch(slice.actions.updateVehicle(response.body));
    return response;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};
