import { createSlice } from '@reduxjs/toolkit';
import { filter, uniqBy, map } from 'lodash';
import { generalConfig } from '../../config';
import { format } from 'date-fns';

const initialState = {
  isLoading: false,
  isLoadingData: false,
  isLoadingKmApertura: false,
  isLoadingKmCierre: false,
  isLoadingCalculateGuide: false,
  error: false,
  maintenanceList: [],
  taskWorkerList: [],
  noveltyListAutocomplete: [],
  checkout: {
    taskList: [],
    materialList: [],
    workerList: [],
    noveltyList: [],
    taskWorkerList: []
  },
  updateCheckout: {},
  total: 0,
  pending: 0,
  approved: 0
};

const slice = createSlice({
  name: 'maintenance',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    endLoading(state) {
      state.isLoading = false;
    },
    startLoadingData(state) {
      state.isLoadingData = true;
    },
    endLoadingData(state) {
      state.isLoadingData = false;
    },
    startLoadingKmApertura(state) {
      state.isLoadingKmApertura = true;
    },
    endLoadingKmApertura(state) {
      state.isLoadingKmApertura = false;
    },
    startLoadingKmCierre(state) {
      state.isLoadingKmCierre = true;
    },
    endLoadingKmCierre(state) {
      state.isLoadingKmCierre = false;
    },
    startLoadingCalculateGuide(state) {
      state.isLoadingCalculateGuide = true;
    },
    endLoadingCalculateGuide(state) {
      state.isLoadingCalculateGuide = false;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setTotal(state, action) {
      state.isLoading = false;
      state.total = action.payload;
    },
    setPending(state, action) {
      state.isLoading = false;
      state.pending = action.payload;
    },
    setApproved(state, action) {
      state.isLoading = false;
      state.approved = action.payload;
    },
    setMaintenanceList(state, action) {
      state.isLoading = false;
      state.maintenanceList = action.payload;
    },
    addMaintenanceSuccess(state, action) {
      state.isLoading = false;
      state.maintenanceList = [...state.maintenanceList, action.payload];
    },
    setSelectedMaintenance(state, action) {
      state.isLoading = false;
      const id = action.payload;
      const index = state.maintenanceList.findIndex((x) => x._id === id);
      const selected = state.maintenanceList[index];
      state.selectedMaintenance = selected;
    },
    setDataByMaintenance(state, action) {
      state.isLoading = false;
    },
    updateMaintenanceSuccess(state, action) {
      state.isLoading = false;
      const maintenance = action.payload;

      const copyList = state.maintenanceList;

      const index = copyList.findIndex(
        (x) => x.id_interno === maintenance.id_interno
      );

      copyList[index] = maintenance;

      state.maintenanceList = copyList;
    },
    removeMaintenanceSuccess(state, action) {
      state.isLoading = false;
      const id_interno = action.payload;

      const copyList = state.maintenanceList;

      const index = copyList.findIndex((x) => x.id_interno === id_interno);

      copyList.splice(index, 1);

      state.maintenanceList = copyList;
    },
    getCheckout(state, action) {
      state.isLoading = false;
      state.checkout = action.payload;
    },
    //TaskCheckout
    setTaskListCheckout(state, action) {
      state.isLoading = false;
      state.checkout.taskList = action.payload;
    },
    addTaskCheckout(state, action) {
      state.isLoading = false;
      const task = action.payload;

      state.checkout.taskList = uniqBy(
        [...state.checkout.taskList, task],
        'id_interno'
      );
    },
    updateTaskCheckout(state, action) {
      state.isLoading = false;
      const task = action.payload;
      const copyList = state.checkout.taskList;
      const index = copyList.findIndex((x) => x.id_interno === task.id_interno);
      copyList[index] = task;
      state.checkout.taskList = copyList;
    },
    removeTaskCheckout(state, action) {
      state.isLoading = false;
      const id_interno = action.payload.id_interno;

      //Delete task from taskList
      const copyList = state.checkout.taskList;
      const index = copyList.findIndex((x) => x.id_interno === id_interno);
      copyList.splice(index, 1);

      //Delete materials with task deleted from materialList
      const materialList = state.checkout.materialList;
      const updatedMaterialList = filter(materialList, (item) => {
        return item.id_tarea !== id_interno;
      });

      state.checkout.taskList = copyList;
      state.checkout.materialList = updatedMaterialList;
    },
    //MaterialCheckout
    addMaterialCheckout(state, action) {
      state.isLoading = false;
      const material = action.payload;
      state.checkout.materialList = uniqBy(
        [...state.checkout.materialList, material],
        'id_interno'
      );
    },
    updateMaterialCheckout(state, action) {
      state.isLoading = false;
      const updatedMaterial = action.payload;
      const copyList = state.checkout.materialList;
      const index = copyList.findIndex(
        (x) => x.id_interno === updatedMaterial.id_interno
      );
      copyList[index] = updatedMaterial;
      state.checkout.materialList = copyList;
    },
    removeMaterialCheckout(state, action) {
      state.isLoading = false;
      const id_interno = action.payload;
      const copyList = state.checkout.materialList;
      const index = copyList.findIndex((x) => x.id_interno === id_interno);
      copyList.splice(index, 1);
      state.checkout.materialList = copyList;
    },
    //WorkerCheckout
    setWorkerListCheckout(state, action) {
      state.isLoading = false;
      state.checkout.workerList = action.payload;
    },
    addWorkerCheckout(state, action) {
      state.isLoading = false;
      const worker = action.payload;

      state.checkout.workerList = uniqBy(
        [...state.checkout.workerList, worker],
        'rut'
      );
    },
    removeWorkerCheckout(state, action) {
      state.isLoading = false;
      const rut = action.payload;
      const copyList = state.checkout.workerList;
      const index = copyList.findIndex((x) => x.rut === rut);
      copyList.splice(index, 1);
      state.checkout.workerList = copyList;
    },
    //NoveltyCheckout
    addNoveltyCheckout(state, action) {
      state.isLoading = false;

      state.checkout.noveltyList = uniqBy(
        [...state.checkout.noveltyList, action.payload],
        'id_interno'
      );
    },
    removeNoveltyCheckout(state, action) {
      state.isLoading = false;
      const id_interno = action.payload;
      const copyList = state.checkout.noveltyList;
      const index = copyList.findIndex((x) => x.id_interno === id_interno);
      copyList.splice(index, 1);
      state.checkout.noveltyList = copyList;
    },
    //Set all checkout list
    setCheckoutList(state, action) {
      state.isLoading = false;
      state.checkout.taskList = action.payload.taskList;
      state.checkout.materialList = action.payload.materialList;
      state.checkout.workerList = action.payload.workerList;
      state.checkout.noveltyList = action.payload.noveltyList;
    },
    resetCheckoutList(state, action) {
      state.isLoading = false;
      state.checkout.taskList = [];
      state.checkout.materialList = [];
      state.checkout.workerList = [];
      state.checkout.noveltyList = [];
      state.checkout.dataCalculateGuide = {};
    },
    //Task and material checkout by guide
    setTaskMaterialListCheckoutByGuide(state, action) {
      state.isLoading = false;
      //Agregamos la acción como PREVENTIVA por defecto
      const taskList = action.payload.tareas;

      const newTaskList = taskList.map((item) => {
        return {
          ...item,
          accion: 'PREVENTIVA'
        };
      });

      const materialList = action.payload.articulos;

      //Agregar cantidad 1 si no viene cantidad de base de datos
      const newMaterialList = materialList.map((item) => {
        return {
          ...item,
          cantidad: item.cantidad ? item.cantidad : 1
        };
      });

      state.checkout.taskList = newTaskList;
      state.checkout.materialList = newMaterialList;
    },
    resetTaskMaterialListCheckoutByGuide(state) {
      state.isLoading = false;
      state.checkout.taskList = [];
      state.checkout.materialList = [];
    },
    setNoveltyListCheckoutByBitacora(state, action) {
      state.isLoading = false;

      const listOnlyPending = filter(action.payload, (item) => {
        return item.estado === 'PENDIENTE';
      });

      state.checkout.noveltyList = [
        ...state.checkout.noveltyList,
        ...listOnlyPending
      ];
    },
    resetNoveltyListCheckoutByBitacora(state, action) {
      state.isLoading = false;
      state.checkout.noveltyList = [];
    },
    setMaterialListCheckoutByTask(state, action) {
      state.isLoading = false;
      state.checkout.materialList = [
        ...state.checkout.materialList,
        ...action.payload
      ];
    },
    //-------------------------------------------------UPDATE CHECKOUT
    setUpdateCheckout(state, action) {
      state.isLoadingData = false;
      const ot = action.payload.ot;
      const vehicle = action.payload.vehiculo;
      const taskList = action.payload.tareas;
      const materialList = action.payload.articulos;
      const workerList = action.payload.personal;
      const noveltyList = action.payload.novedades;
      const taskWorkerList = action.payload.asignaciones;
      const history = action.payload.historia;

      state.updateCheckout = {
        ot: ot,
        vehicle: vehicle,
        taskList: taskList,
        materialList: materialList,
        workerList: workerList,
        noveltyList: noveltyList,
        taskWorkerList: taskWorkerList,
        history: history
      };
    },
    //TaskUpdateCheckout
    setTaskListUpdateCheckout(state, action) {
      state.isLoading = false;
      state.updateCheckout.taskList = action.payload;
    },
    addTaskUpdateCheckout(state, action) {
      state.isLoading = false;
      const task = action.payload.tarea;
      const newMaterialList = action.payload.insumos;

      state.updateCheckout.taskList = uniqBy(
        [...state.updateCheckout.taskList, task],
        'id_interno'
      );

      const taskWorker = {
        ...task,
        id_interno: task.id_ot_tareas,
        id_tarea: task.id_interno,
        fecha_inicio: null,
        fecha_inicio_format: null,
        fecha_termino: null,
        fecha_termino_format: null,
        nombre_empleado: null,
        nuevo_estado: 'PENDIENTE',
        rut_empleado: null,
        tarea: task.descripcion
      };

      state.updateCheckout.taskWorkerList = [
        ...state.updateCheckout.taskWorkerList,
        taskWorker
      ];
      if (newMaterialList.length > 0) {
        state.updateCheckout.materialList = newMaterialList;
      }
    },
    updateTaskUpdateCheckout(state, action) {
      state.isLoading = false;
      const updatedTask = action.payload;
      const copyList = state.updateCheckout.taskList;
      const index = copyList.findIndex(
        (x) => x.id_interno === updatedTask.id_interno
      );
      copyList[index] = updatedTask;
      state.updateCheckout.taskList = copyList;
    },
    removeTaskUpdateCheckout(state, action) {
      state.isLoading = false;
      //eliminamos la tarea
      const id_interno = action.payload.id_tarea;
      const insumos = action.payload.insumos;
      const copyList = state.updateCheckout.taskList;
      const index = copyList.findIndex((x) => x.id_interno === id_interno);
      copyList.splice(index, 1);
      state.updateCheckout.taskList = copyList;
      //eliminamos la asignación
      const copyTaskWorkerList = state.updateCheckout.taskWorkerList;
      const updatedList = filter(
        copyTaskWorkerList,
        (item) => item.id_tarea !== id_interno
      );
      state.updateCheckout.taskWorkerList = updatedList;
      if (insumos.length > 0) {
        state.updateCheckout.materialList = insumos;
      }
      //
    },
    //MaterialUpdateCheckout
    setMaterialListUpdateCheckout(state, action) {
      state.isLoading = false;
      state.updateCheckout.materialList = action.payload;
    },
    addMaterialUpdateCheckout(state, action) {
      state.isLoading = false;
      const task = action.payload;

      state.updateCheckout.materialList = uniqBy(
        [...state.updateCheckout.materialList, task],
        'id_interno'
      );
    },
    updateMaterialUpdateCheckout(state, action) {
      state.isLoading = false;
      const updatedMaterial = action.payload;
      const copyList = state.updateCheckout.materialList;
      const index = copyList.findIndex(
        (x) => x.id_interno === updatedMaterial.id_interno
      );
      copyList[index] = updatedMaterial;
      state.updateCheckout.materialList = copyList;
    },
    removeMaterialUpdateCheckout(state, action) {
      state.isLoading = false;
      const id_interno = action.payload;
      const copyList = state.updateCheckout.materialList;
      const index = copyList.findIndex((x) => x.id_interno === id_interno);
      copyList.splice(index, 1);
      state.updateCheckout.materialList = copyList;
    },
    updateMaterialUpdateCheckoutSolicitudBodega(state, action) {
      state.isLoading = false;
      const id_solicitud = action.payload;
      const copyList = state.updateCheckout.materialList;
      const updatedList = map(copyList, (item) => {
        if (item.id_solicitud === 0) {
          return {
            ...item,
            id_solicitud: id_solicitud
          };
        }
        return item;
      });
      state.updateCheckout.materialList = updatedList;
    },
    //PersonalUpdateCheckout
    setPersonalListUpdateCheckout(state, action) {
      state.isLoading = false;
      state.updateCheckout.workerList = action.payload;
    },
    addWorkerUpdateCheckout(state, action) {
      state.isLoading = false;
      const worker = action.payload;

      state.updateCheckout.workerList = uniqBy(
        [...state.updateCheckout.workerList, worker],
        'id_interno'
      );
    },
    removeWorkerUpdateCheckout(state, action) {
      state.isLoading = false;
      const rut = action.payload;
      const copyList = state.updateCheckout.workerList;
      const index = copyList.findIndex((x) => x.rut === rut);
      copyList.splice(index, 1);
      state.updateCheckout.workerList = copyList;
    },
    //NoveltyUpdateCheckout
    setNoveltyListUpdateCheckout(state, action) {
      state.isLoading = false;
      state.updateCheckout.noveltyList = action.payload;
    },
    addNoveltyUpdateCheckout(state, action) {
      state.isLoading = false;
      const novelty = action.payload;

      state.updateCheckout.noveltyList = uniqBy(
        [...state.updateCheckout.noveltyList, novelty],
        'id_interno'
      );
    },
    updateNoveltyUpdateCheckout(state, action) {
      state.isLoading = false;
      const updatedNovelty = action.payload;
      const copyList = state.updateCheckout.noveltyList;
      const index = copyList.findIndex(
        (x) => x.id_interno === updatedNovelty.id_interno
      );
      copyList[index] = updatedNovelty;
      state.updateCheckout.noveltyList = copyList;
    },
    removeNoveltyUpdateCheckout(state, action) {
      state.isLoading = false;
      const id_interno = action.payload;
      const copyList = state.updateCheckout.noveltyList;
      const index = copyList.findIndex((x) => x.id_interno === id_interno);
      copyList.splice(index, 1);
      state.updateCheckout.noveltyList = copyList;
    },
    //TaskWorkerUpdateCheckout
    setTaskWorkerListUpdateCheckout(state, action) {
      state.isLoading = false;
      state.updateCheckout.taskWorkerList = action.payload;
    },
    updateTaskWorkerUpdateCheckout(state, action) {
      state.isLoading = false;
      const updatedTaskWorker = action.payload;
      const copyList = state.updateCheckout.taskWorkerList;
      const index = copyList.findIndex(
        (x) => x.id_interno === updatedTaskWorker.id_interno
      );
      copyList[index] = updatedTaskWorker;
      state.updateCheckout.taskWorkerList = copyList;
    },
    setTaskWorkerList(state, action) {
      state.isLoading = false;
      state.taskWorkerList = action.payload;
    },
    setNoveltyListByBitacoraForAutocomplete(state, action) {
      state.isLoading = false;
      state.noveltyListAutocomplete = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  addTaskCheckout,
  updateTaskCheckout,
  removeTaskCheckout,
  addMaterialCheckout,
  updateMaterialCheckout,
  removeMaterialCheckout,
  addWorkerCheckout,
  removeWorkerCheckout,
  addNoveltyCheckout,
  removeNoveltyCheckout,
  addMaintenanceSuccess,
  getCheckout,
  resetTaskMaterialListCheckoutByGuide,
  setCheckoutList,
  resetCheckoutList,
  resetNoveltyListCheckoutByBitacora
} = slice.actions;

// ----------------------------------------------------------------------

export function getMaintenanceList() {
  const accessToken = window.localStorage.getItem('accessToken');
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const query = await fetch(`${generalConfig.baseUrl}/api/v1/ots`, {
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      });
      const response = await query.json();

      dispatch(slice.actions.setMaintenanceList(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export const addMaintenance = (values) => async (dispatch) => {
  dispatch(slice.actions.startLoading());
  try {
    const query = await fetch(`${generalConfig.baseUrl}/api/v1/agregar_ot`, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json'
      }
    });
    const response = await query.json();
    if (response.message === 'error') {
      dispatch(slice.actions.endLoading());
      return response;
    }
    dispatch(slice.actions.addMaintenanceSuccess(response.body));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};

// ----------------------------------------------------------------------

export const updateMaintenance = (values) => async (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken');
  dispatch(slice.actions.startLoading());
  try {
    const query = await fetch(`${generalConfig.baseUrl}/api/v1/editar_ot`, {
      method: 'PUT',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${accessToken}`
      }
    });
    const response = await query.json();
    if (response.message === 'error') {
      return response;
    }
    dispatch(slice.actions.updateMaintenanceSuccess(response.body));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};

// ----------------------------------------------------------------------

export function updateStateMaintenance(values) {
  const accessToken = window.localStorage.getItem('accessToken');
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const query = await fetch(
        `${generalConfig.baseUrl}/api/v1/actualizar_estado_ot`,
        {
          method: 'PUT',
          body: JSON.stringify(values),
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${accessToken}`
          }
        }
      );
      const response = await query.json();

      if (response.message === 'error') {
        return response;
      }

      dispatch(slice.actions.updateMaintenanceSuccess(response.body));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export const removeMaintenance = (id_interno) => async (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken');
  dispatch(slice.actions.startLoading());
  try {
    const query = await fetch(
      `${generalConfig.baseUrl}/api/v1/eliminar_ot/${id_interno}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      }
    );
    const response = await query.json();
    if (response.message === 'error') {
      return response;
    }
    dispatch(slice.actions.removeMaintenanceSuccess(id_interno));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};

// ----------------------------------------------------------------------

export function getMaintenanceById(id_interno, id_vehiculo) {
  const accessToken = window.localStorage.getItem('accessToken');
  return async (dispatch) => {
    dispatch(slice.actions.startLoadingData());
    try {
      const query = await fetch(
        `${generalConfig.baseUrl}/api/v1/ot/${id_interno}/${id_vehiculo}`,
        {
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${accessToken}`
          }
        }
      );
      const response = await query.json();
      // console.log('updatedCheckout', response);

      dispatch(slice.actions.setUpdateCheckout(response));
      // return response;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getTaskMaterialListCheckoutByGuide(id_interno) {
  const accessToken = window.localStorage.getItem('accessToken');
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const query = await fetch(
        `${generalConfig.baseUrl}/api/v1/tareas_articulos_por_pauta/${id_interno}`,
        {
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${accessToken}`
          }
        }
      );
      const response = await query.json();

      dispatch(slice.actions.setTaskMaterialListCheckoutByGuide(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getMaterialListCheckoutByTask(id_tarea) {
  const accessToken = window.localStorage.getItem('accessToken');
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const query = await fetch(
        `${generalConfig.baseUrl}/api/v1/articulos_por_tarea/${id_tarea}`,
        {
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${accessToken}`
          }
        }
      );
      const response = await query.json();

      const newList = response.map((item) => {
        return {
          ...item,
          cantidad: item.cantidad ? item.cantidad : 1,
          id_solicitud: 0,
          id_articulo: item.id_interno
        };
      });

      // console.log('esto llego', response);

      dispatch(slice.actions.setMaterialListCheckoutByTask(newList));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getTaskListCheckout(id_ot) {
  const accessToken = window.localStorage.getItem('accessToken');
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const query = await fetch(
        `${generalConfig.baseUrl}/api/v1/tareas_por_ot/${id_ot}`,
        {
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${accessToken}`
          }
        }
      );
      const response = await query.json();

      dispatch(slice.actions.setTaskListCheckout(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getWorkerListCheckout(id_ot) {
  const accessToken = window.localStorage.getItem('accessToken');
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    let data = [];

    try {
      const query = await fetch(
        `${generalConfig.baseUrl}/api/v1/personal_interno_por_ot/${id_ot}`,
        {
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${accessToken}`
          }
        }
      );
      const response = await query.json();

      data = [...response];
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }

    try {
      const query = await fetch(
        `${generalConfig.baseUrl}/api/v1/personal_externo_por_ot/${id_ot}`,
        {
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${accessToken}`
          }
        }
      );
      const response = await query.json();

      data = [...data, ...response];
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
    dispatch(slice.actions.setWorkerListCheckout(data));
  };
}

// ----------------------------------------------------------------------TASK UPDATE

export function getTaskByMaintenance(id_ot) {
  const accessToken = window.localStorage.getItem('accessToken');
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const query = await fetch(
        `${generalConfig.baseUrl}/api/v1/tareas_por_ot/${id_ot}`,
        {
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${accessToken}`
          }
        }
      );
      const response = await query.json();

      dispatch(slice.actions.setTaskListUpdateCheckout(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export const addTaskToMaintenance = (values) => async (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken');
  dispatch(slice.actions.startLoading());
  try {
    const query = await fetch(
      `${generalConfig.baseUrl}/api/v1/agregar_tarea_a_ot`,
      {
        method: 'POST',
        body: JSON.stringify(values),
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      }
    );
    const response = await query.json();

    if (response.message === 'error') {
      return response;
    }
    dispatch(slice.actions.addTaskUpdateCheckout(response.body));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};

// ----------------------------------------------------------------------

export const updateTaskInMaintenance = (values) => async (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken');
  dispatch(slice.actions.startLoading());
  try {
    const query = await fetch(
      `${generalConfig.baseUrl}/api/v1/actualizar_tarea_en_ot`,
      {
        method: 'PUT',
        body: JSON.stringify(values),
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      }
    );
    const response = await query.json();

    if (response.message === 'error') {
      return response;
    }
    dispatch(slice.actions.updateTaskUpdateCheckout(response.body));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};

// ----------------------------------------------------------------------

export const removeTaskFromMaintenance = (id_tarea, id_ot) => async (
  dispatch
) => {
  const accessToken = window.localStorage.getItem('accessToken');
  dispatch(slice.actions.startLoading());
  try {
    const query = await fetch(
      `${generalConfig.baseUrl}/api/v1/eliminar_tarea_de_ot/${id_tarea}/${id_ot}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      }
    );
    const response = await query.json();
    if (response.message === 'error') {
      return response;
    }
    dispatch(
      slice.actions.removeTaskUpdateCheckout({
        id_tarea: id_tarea,
        insumos: response.body
      })
    );
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};

// ----------------------------------------------------------------------ARTICULO UPDATE

export function getMaterialByMaintenance(id_ot) {
  const accessToken = window.localStorage.getItem('accessToken');
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const query = await fetch(
        `${generalConfig.baseUrl}/api/v1/articulos_por_ot/${id_ot}`,
        {
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${accessToken}`
          }
        }
      );
      const response = await query.json();

      dispatch(slice.actions.setMaterialListUpdateCheckout(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export const addMaterialToMaintenance = (values) => async (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken');
  dispatch(slice.actions.startLoading());
  try {
    const query = await fetch(
      `${generalConfig.baseUrl}/api/v1/agregar_articulo_a_ot`,
      {
        method: 'POST',
        body: JSON.stringify(values),
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      }
    );
    const response = await query.json();

    if (response.message === 'error') {
      return response;
    }
    dispatch(slice.actions.addMaterialUpdateCheckout(response.body));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};

// ----------------------------------------------------------------------

export const updateMaterialToMaintenance = (values) => async (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken');
  dispatch(slice.actions.startLoading());
  try {
    const query = await fetch(
      `${generalConfig.baseUrl}/api/v1/actualizar_articulo_a_ot`,
      {
        method: 'POST',
        body: JSON.stringify(values),
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      }
    );
    const response = await query.json();

    if (response.message === 'error') {
      return response;
    }
    dispatch(slice.actions.updateMaterialUpdateCheckout(response.body));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};

// ----------------------------------------------------------------------

export const removeMaterialFromMaintenance = (id_material, id_ot) => async (
  dispatch
) => {
  const accessToken = window.localStorage.getItem('accessToken');
  dispatch(slice.actions.startLoading());
  try {
    const query = await fetch(
      `${generalConfig.baseUrl}/api/v1/eliminar_material_de_ot/${id_material}/${id_ot}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      }
    );
    const response = await query.json();

    if (response.message === 'error') {
      return response;
    }
    dispatch(slice.actions.removeMaterialUpdateCheckout(id_material));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};

// ----------------------------------------------------------------------PERSONAL UPDATE

export function getPersonalByMaintenance(id_ot) {
  const accessToken = window.localStorage.getItem('accessToken');
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const query = await fetch(
        `${generalConfig.baseUrl}/api/v1/hh_por_ot/${id_ot}`,
        {
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${accessToken}`
          }
        }
      );
      const response = await query.json();

      dispatch(slice.actions.setPersonalListUpdateCheckout(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export const addPersonalToMaintenance = (values) => async (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken');
  dispatch(slice.actions.startLoading());
  try {
    const query = await fetch(
      `${generalConfig.baseUrl}/api/v1/agregar_hh_a_ot`,
      {
        method: 'POST',
        body: JSON.stringify(values),
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      }
    );
    const response = await query.json();

    if (response.message === 'error') {
      return response;
    }
    dispatch(slice.actions.addWorkerUpdateCheckout(response.body));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};

// ----------------------------------------------------------------------

export const removePersonalFromMaintenance = (rut, id_ot) => async (
  dispatch
) => {
  const accessToken = window.localStorage.getItem('accessToken');
  dispatch(slice.actions.startLoading());
  try {
    const query = await fetch(
      `${generalConfig.baseUrl}/api/v1/eliminar_hh_de_ot/${rut}/${id_ot}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      }
    );
    const response = await query.json();
    if (response.message === 'error') {
      return response;
    }
    dispatch(slice.actions.removeWorkerUpdateCheckout(rut));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};

// ----------------------------------------------------------------------NOVELTY UPDATE

export function getNoveltyListCheckoutByBitacora(id_bitacora) {
  const accessToken = window.localStorage.getItem('accessToken');
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const query = await fetch(
        `${generalConfig.baseUrl}/api/v1/novedades_por_bitacora/${id_bitacora}`,
        {
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${accessToken}`
          }
        }
      );
      const response = await query.json();

      dispatch(slice.actions.setNoveltyListCheckoutByBitacora(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getNoveltyByBitacoraForAutocomplete(id_bitacora) {
  const accessToken = window.localStorage.getItem('accessToken');
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const query = await fetch(
        `${generalConfig.baseUrl}/api/v1/novedades_por_bitacora_para_autocomplete/${id_bitacora}`,
        {
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${accessToken}`
          }
        }
      );
      const response = await query.json();

      dispatch(slice.actions.setNoveltyListByBitacoraForAutocomplete(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getNoveltyByMaintenance(id_ot) {
  const accessToken = window.localStorage.getItem('accessToken');
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const query = await fetch(
        `${generalConfig.baseUrl}/api/v1/novedades_por_ot/${id_ot}`,
        {
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${accessToken}`
          }
        }
      );
      const response = await query.json();

      dispatch(slice.actions.setNoveltyListUpdateCheckout(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export const addNoveltyToMaintenance = (values) => async (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken');
  dispatch(slice.actions.startLoading());
  try {
    const query = await fetch(
      `${generalConfig.baseUrl}/api/v1/agregar_novedad_a_ot`,
      {
        method: 'POST',
        body: JSON.stringify(values),
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      }
    );
    const response = await query.json();

    if (response.message === 'error') {
      return response;
    }
    dispatch(slice.actions.addNoveltyUpdateCheckout(response.body));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};

// ----------------------------------------------------------------------

export const updateNoveltyInMaintenance = (values) => async (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken');
  dispatch(slice.actions.startLoading());
  try {
    const query = await fetch(
      `${generalConfig.baseUrl}/api/v1/actualizar_novedad_en_ot`,
      {
        method: 'POST',
        body: JSON.stringify(values),
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      }
    );
    const response = await query.json();

    if (response.message === 'error') {
      return response;
    }
    dispatch(slice.actions.updateNoveltyUpdateCheckout(response.body));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};

// ----------------------------------------------------------------------

export const removeNoveltyFromMaintenance = (id_novedad, id_ot) => async (
  dispatch
) => {
  const accessToken = window.localStorage.getItem('accessToken');
  dispatch(slice.actions.startLoading());
  try {
    const query = await fetch(
      `${generalConfig.baseUrl}/api/v1/eliminar_novedad_de_ot/${id_novedad}/${id_ot}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      }
    );
    const response = await query.json();
    if (response.message === 'error') {
      return response;
    }
    dispatch(slice.actions.removeNoveltyUpdateCheckout(id_novedad));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};

// ----------------------------------------------------------------------TASK-WORKER UPDATE

export function getTaskWorkerList() {
  const accessToken = window.localStorage.getItem('accessToken');
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const query = await fetch(
        `${generalConfig.baseUrl}/api/v1/tareas_hh_por_ot`,
        {
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${accessToken}`
          }
        }
      );
      const response = await query.json();

      dispatch(slice.actions.setTaskWorkerList(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getTaskWorkerByMaintenance(id_ot) {
  const accessToken = window.localStorage.getItem('accessToken');
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const query = await fetch(
        `${generalConfig.baseUrl}/api/v1/tareas_hh_por_ot/${id_ot}`,
        {
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${accessToken}`
          }
        }
      );
      const response = await query.json();

      dispatch(slice.actions.setTaskWorkerListUpdateCheckout(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export const updateTaskWorkerInMaintenance = (values) => async (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken');
  dispatch(slice.actions.startLoading());
  try {
    const query = await fetch(
      `${generalConfig.baseUrl}/api/v1/actualizar_tarea_hh_en_ot`,
      {
        method: 'PUT',
        body: JSON.stringify(values),
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      }
    );
    const response = await query.json();

    if (response.message === 'error') {
      return response;
    }
    dispatch(slice.actions.updateTaskWorkerUpdateCheckout(response.body));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};

// ----------------------------------------------------------------------TOTALES

export function getTotalMaintenance() {
  const accessToken = window.localStorage.getItem('accessToken');
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const query = await fetch(
        `${generalConfig.baseUrl}/api/v1/ots_contador`,
        {
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${accessToken}`
          }
        }
      );
      const response = await query.json();

      dispatch(slice.actions.setTotal(response.contador));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getTotalPendingMaintenance() {
  const accessToken = window.localStorage.getItem('accessToken');
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const query = await fetch(
        `${generalConfig.baseUrl}/api/v1/ots_contador_pendientes`,
        {
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${accessToken}`
          }
        }
      );
      const response = await query.json();

      dispatch(slice.actions.setPending(response.contador));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getTotalApprovedMaintenance() {
  const accessToken = window.localStorage.getItem('accessToken');
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const query = await fetch(
        `${generalConfig.baseUrl}/api/v1/ots_contador_aprobados`,
        {
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${accessToken}`
          }
        }
      );
      const response = await query.json();

      dispatch(slice.actions.setApproved(response.contador));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------END TOTALES

export function getPermissionPending(values) {
  const accessToken = window.localStorage.getItem('accessToken');
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const query = await fetch(
        `${generalConfig.baseUrl}/api/v1/solicitar_permiso_pendiente`,
        {
          method: 'POST',
          body: JSON.stringify(values),
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${accessToken}`
          }
        }
      );
      const response = await query.json();

      if (response.message === 'error') {
        dispatch(slice.actions.endLoading());
        return response;
      }
    } catch (error) {
      dispatch(slice.actions.hasError(values));
    }
  };
}

// ----------------------------------------------------------------------

export function getPermissionApproved(values) {
  const accessToken = window.localStorage.getItem('accessToken');
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const query = await fetch(
        `${generalConfig.baseUrl}/api/v1/solicitar_permiso_aprobada`,
        {
          method: 'POST',
          body: JSON.stringify(values),
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${accessToken}`
          }
        }
      );
      const response = await query.json();

      if (response.message === 'error') {
        dispatch(slice.actions.endLoading());
        return response;
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPermissionInProcess(values) {
  const accessToken = window.localStorage.getItem('accessToken');
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const query = await fetch(
        `${generalConfig.baseUrl}/api/v1/solicitar_permiso_en_proceso`,
        {
          method: 'POST',
          body: JSON.stringify(values),
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${accessToken}`
          }
        }
      );
      const response = await query.json();

      if (response.message === 'error') {
        dispatch(slice.actions.endLoading());
        return response;
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPermissionMaterials(id_ot, uid, id_empleado) {
  const accessToken = window.localStorage.getItem('accessToken');
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const query = await fetch(
        `${generalConfig.baseUrl}/api/v1/solicitar_permiso_insumos`,
        {
          method: 'POST',
          body: JSON.stringify({
            id_ot: id_ot,
            id_usuario: uid,
            id_empleado: id_empleado
          }),
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${accessToken}`
          }
        }
      );
      const response = await query.json();
      console.log('response al ingresar solicitud a bodega', response);
      dispatch(
        slice.actions.updateMaterialUpdateCheckoutSolicitudBodega(response.body)
      );
      return response;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function sendPetitionMaterials(id_ot, uid) {
  const accessToken = window.localStorage.getItem('accessToken');
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const query = await fetch(
        `${generalConfig.baseUrl}/api/v1/solicitar_articulos_a_bodega_por_ot/${id_ot}/${uid}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${accessToken}`
          }
        }
      );
      const response = await query.json();

      dispatch(slice.actions.endLoading());
      return response;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function sendPetitionUpdate(id_ot, uid, folio) {
  const accessToken = window.localStorage.getItem('accessToken');
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const query = await fetch(
        `${generalConfig.baseUrl}/api/v1/solicitar_permiso_actualizar`,
        {
          method: 'POST',
          body: JSON.stringify({
            id_ot: id_ot,
            id_usuario: uid,
            folio: folio
          }),
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${accessToken}`
          }
        }
      );
      const response = await query.json();
      dispatch(slice.actions.endLoading());
      return response;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function sendPetitionRemove(id_ot, uid, folio, observacion) {
  const accessToken = window.localStorage.getItem('accessToken');
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const query = await fetch(
        `${generalConfig.baseUrl}/api/v1/solicitar_permiso_eliminar`,
        {
          method: 'POST',
          body: JSON.stringify({
            id_ot: id_ot,
            id_usuario: uid,
            folio: folio,
            observacion: observacion
          }),
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${accessToken}`
          }
        }
      );
      const response = await query.json();
      dispatch(slice.actions.endLoading());
      return response;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function changeEditPermission(id_ot) {
  const accessToken = window.localStorage.getItem('accessToken');
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const query = await fetch(
        `${generalConfig.baseUrl}/api/v1/cambiar_permiso_actualizacion/${id_ot}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${accessToken}`
          }
        }
      );
      const response = await query.json();
      dispatch(slice.actions.endLoading());
      return response;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ---------------------------------------------------------------------- GPS Y CONSULTA DE PAUTAS

// ----------------------------------------------------------------------

export const getKmByGps = (vehicle) => async (dispatch) => {
  const accessToken = window.localStorage.getItem('accessToken');
  dispatch(slice.actions.startLoadingKmApertura());
  //Datos
  const gps = vehicle.gps;
  const numero = vehicle.numero;
  const patente = vehicle.patente;
  const patenteFormato = patente.substring(0, 6);
  const ahora = new Date();

  if (gps === 'EPOL') {
    const hasta = format(ahora, 'yyyy-MM-dd HH:mm:ss');
    // const desde = ahora.setMonth(ahora.getMonth() - 9);
    const desde = format(
      ahora.setMonth(ahora.getMonth() - 6),
      'yyyy-MM-dd HH:mm:ss'
    );
    try {
      const query = await fetch(
        `https://integration.readymix.io/api/vehicles/BUS ${numero}/positions?date_time_from=${desde}&date_time_to=${hasta}&fields=position.latitude,position.longitude,rmt.odo,timestamp`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            token: '10f7a1f5-7b70-406a-9c3b-78aafece'
          }
        }
      );
      const response = await query.json();
      if (response.message === 'error') {
        dispatch(slice.actions.endLoadingKmApertura());
        return {
          message: 'error',
          body: 'El proveedor GPS EPOL no envió ningún kilometraje'
        };
      } else {
        const { data } = response;

        const registro = data[data.length - 1];
        const kilometraje = parseInt(registro['rmt.odo']);
        const fechaRegistro = new Date(registro.timestamp * 1000);

        if (kilometraje) {
          dispatch(slice.actions.endLoadingKmApertura());
          return {
            message: 'success',
            body: {
              kilometraje,
              fechaRegistro
            }
          };
        } else {
          dispatch(slice.actions.endLoadingKmApertura());
          return {
            message: 'error',
            body: 'El valor enviado por GPS EPOL no es válido como kilometraje'
          };
        }
      }
    } catch (error) {
      dispatch(slice.actions.endLoadingKmApertura());
      return {
        message: 'error',
        body: 'No se pudo conectar al proveedor GPS EPOL'
      };
    }
  } else if (gps === 'GPSCHILE') {
    try {
      const query = await fetch(`${generalConfig.baseUrl}/gps_chile`, {
        method: 'POST',
        body: JSON.stringify({
          patente: patenteFormato
        }),
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      });
      const response = await query.json();
      if (response.message === 'error') {
        dispatch(slice.actions.endLoadingKmApertura());
        return {
          message: 'error',
          body: 'El proveedor GPS CHILE no envió ningún kilometraje'
        };
      } else {
        const { OdometroCalibrado, fecha } = response.body.ultima_actividad;
        const kilometraje = parseInt(OdometroCalibrado);
        const fechaRegistro = new Date(fecha);
        if (kilometraje) {
          dispatch(slice.actions.endLoadingKmApertura());
          return {
            message: 'success',
            body: {
              kilometraje,
              fechaRegistro
            }
          };
        } else {
          dispatch(slice.actions.endLoadingKmApertura());
          return {
            message: 'error',
            body: 'El valor enviado por GPS CHILE no es válido como kilometraje'
          };
        }
      }
    } catch (error) {
      dispatch(slice.actions.endLoadingKmApertura());
      return {
        message: 'error',
        body: 'No se pudo conectar al proveedor GPS CHILE'
      };
    }
  } else {
    dispatch(slice.actions.endLoadingKmApertura());
    return { message: 'error', body: 'No reconozco a este proveedor de GPS' };
  }
};

// ----------------------------------------------------------------------

export const getKmByGpsForKmsAperturaUpdate = (gps, numero, patente) => async (
  dispatch
) => {
  const accessToken = window.localStorage.getItem('accessToken');
  dispatch(slice.actions.startLoadingKmApertura());
  const patenteFormato = patente.substring(0, 6);
  const ahora = new Date();

  if (gps === 'EPOL') {
    const hasta = format(ahora, 'yyyy-MM-dd HH:mm:ss');
    // const desde = ahora.setMonth(ahora.getMonth() - 9);
    const desde = format(
      ahora.setMonth(ahora.getMonth() - 6),
      'yyyy-MM-dd HH:mm:ss'
    );
    try {
      const query = await fetch(
        `https://integration.readymix.io/api/vehicles/BUS ${numero}/positions?date_time_from=${desde}&date_time_to=${hasta}&fields=position.latitude,position.longitude,rmt.odo,timestamp`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            token: '10f7a1f5-7b70-406a-9c3b-78aafece'
          }
        }
      );
      const response = await query.json();
      if (response.message === 'error') {
        dispatch(slice.actions.endLoadingKmApertura());
        return {
          message: 'error',
          body: 'El proveedor GPS EPOL no envió ningún kilometraje'
        };
      } else {
        const { data } = response;

        const registro = data[data.length - 1];
        const kilometraje = parseInt(registro['rmt.odo']);
        const fechaRegistro = new Date(registro.timestamp * 1000);

        if (kilometraje) {
          dispatch(slice.actions.endLoadingKmApertura());
          return {
            message: 'success',
            body: { kilometraje, fechaRegistro }
          };
        } else {
          dispatch(slice.actions.endLoadingKmApertura());
          return {
            message: 'error',
            body: 'El valor enviado por GPS EPOL no es válido como kilometraje'
          };
        }
      }
    } catch (error) {
      dispatch(slice.actions.endLoadingKmApertura());
      return {
        message: 'error',
        body: 'No se pudo conectar al proveedor GPS EPOL'
      };
    }
  } else if (gps === 'GPSCHILE') {
    try {
      const query = await fetch(`${generalConfig.baseUrl}/gps_chile`, {
        method: 'POST',
        body: JSON.stringify({
          patente: patenteFormato
        }),
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      });
      const response = await query.json();
      if (response.message === 'error') {
        dispatch(slice.actions.endLoadingKmApertura());
        return {
          message: 'error',
          body: 'El proveedor GPS CHILE no envió ningún kilometraje'
        };
      } else {
        const { OdometroCalibrado, fecha } = response.body.ultima_actividad;
        const fechaRegistro = new Date(fecha);
        const kilometraje = parseInt(OdometroCalibrado);
        if (OdometroCalibrado) {
          dispatch(slice.actions.endLoadingKmApertura());
          return {
            message: 'success',
            body: { kilometraje, fechaRegistro }
          };
        } else {
          dispatch(slice.actions.endLoadingKmApertura());
          return {
            message: 'error',
            body: 'El valor enviado por GPS CHILE no es válido como kilometraje'
          };
        }
      }
    } catch (error) {
      dispatch(slice.actions.endLoadingKmApertura());
      return {
        message: 'error',
        body: 'No se pudo conectar al proveedor GPS CHILE'
      };
    }
  } else {
    dispatch(slice.actions.endLoadingKmApertura());
    return { message: 'error', body: 'No reconozco a este proveedor de GPS' };
  }
};

// ----------------------------------------------------------------------

export const getKmByGpsForKmsCierre = (gps, numero, patente) => async (
  dispatch
) => {
  const accessToken = window.localStorage.getItem('accessToken');
  dispatch(slice.actions.startLoadingKmCierre());
  const patenteFormato = patente.substring(0, 6);
  const ahora = new Date();

  if (gps === 'EPOL') {
    const hasta = format(ahora, 'yyyy-MM-dd HH:mm:ss');
    // const desde = ahora.setMonth(ahora.getMonth() - 9);
    const desde = format(
      ahora.setMonth(ahora.getMonth() - 6),
      'yyyy-MM-dd HH:mm:ss'
    );
    try {
      const query = await fetch(
        `https://integration.readymix.io/api/vehicles/BUS ${numero}/positions?date_time_from=${desde}&date_time_to=${hasta}&fields=position.latitude,position.longitude,rmt.odo,timestamp`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            token: '10f7a1f5-7b70-406a-9c3b-78aafece'
          }
        }
      );
      const response = await query.json();
      if (response.message === 'error') {
        dispatch(slice.actions.endLoadingKmCierre());
        return {
          message: 'error',
          body: 'El proveedor GPS EPOL no envió ningún kilometraje'
        };
      } else {
        const { data } = response;

        const registro = data[data.length - 1];
        const kilometraje = parseInt(registro['rmt.odo']);
        const fechaRegistro = new Date(registro.timestamp * 1000);

        if (kilometraje) {
          dispatch(slice.actions.endLoadingKmCierre());
          return {
            message: 'success',
            body: { kilometraje, fechaRegistro }
          };
        } else {
          dispatch(slice.actions.endLoadingKmCierre());
          return {
            message: 'error',
            body: 'El valor enviado por GPS EPOL no es válido como kilometraje'
          };
        }
      }
    } catch (error) {
      dispatch(slice.actions.endLoadingKmCierre());
      return {
        message: 'error',
        body: 'No se pudo conectar al proveedor GPS EPOL'
      };
    }
  } else if (gps === 'GPSCHILE') {
    try {
      const query = await fetch(`${generalConfig.baseUrl}/gps_chile`, {
        method: 'POST',
        body: JSON.stringify({
          patente: patenteFormato
        }),
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      });
      const response = await query.json();
      if (response.message === 'error') {
        dispatch(slice.actions.endLoadingKmCierre());
        return {
          message: 'error',
          body: 'El proveedor GPS CHILE no envió ningún kilometraje'
        };
      } else {
        const { OdometroCalibrado, fecha } = response.body.ultima_actividad;
        const kilometraje = parseInt(OdometroCalibrado);
        const fechaRegistro = new Date(fecha);
        if (kilometraje) {
          dispatch(slice.actions.endLoadingKmCierre());
          return {
            message: 'success',
            body: { kilometraje, fechaRegistro }
          };
        } else {
          dispatch(slice.actions.endLoadingKmCierre());
          return {
            message: 'error',
            body: 'El valor enviado por GPS CHILE no es válido como kilometraje'
          };
        }
      }
    } catch (error) {
      dispatch(slice.actions.endLoadingKmCierre());
      return {
        message: 'error',
        body: 'No se pudo conectar al proveedor GPS CHILE'
      };
    }
  } else {
    dispatch(slice.actions.endLoadingKmCierre());
    return { message: 'error', body: 'No reconozco a este proveedor de GPS' };
  }
};

// ----------------------------------------------------------------------

export function calculateSchedule(id_vehiculo) {
  const accessToken = window.localStorage.getItem('accessToken');
  return async (dispatch) => {
    dispatch(slice.actions.startLoadingCalculateGuide());
    try {
      const query = await fetch(
        `${generalConfig.baseUrl}/api/v1/calcular_siguiente_cronograma/${id_vehiculo}`,
        {
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${accessToken}`
          }
        }
      );
      const response = await query.json();
      dispatch(slice.actions.endLoadingCalculateGuide());
      return response;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ---------------------------------------------------------------------- END GPS Y CONSULTA DE PAUTAS
